
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';
// 多语种动态日志-- 目前只处理分销商系统涉及的日志
import {setConfigCharacters} from "@/utils/common";

export const dynamicLog = (obj, contentType, system) => {
  // 动态类型值
  let typeObj = {
    LF: alias2f085dd487c1465383484787b29fef2c.t('key1001469'),
    FT: alias2f085dd487c1465383484787b29fef2c.t('key1001470')
  }

  // 处理地址的动态日志数据问题
  function handleAddress(obj) {
    let newList = [];
    let addressLoglist = [
      {
        paramsKey: ['oldBuyerName', 'newBuyerName'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001295')} [ ${obj.oldBuyerName} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerName} ]`
      },
      {
        paramsKey: ['oldBuyerEmail', 'newBuyerEmail'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001296')} [ ${obj.oldBuyerEmail} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerEmail} ]`
      },
      {
        paramsKey: ['oldBuyerPhone', 'newBuyerPhone'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001297')} [ ${obj.oldBuyerPhone} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerPhone} ]`
      },
      {
        paramsKey: ['oldBuyerAccountId', 'newBuyerAccountId'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001298')} [ ${obj.oldBuyerAccountId} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerAccountId} ]`
      },
      {
        paramsKey: ['oldBuyerCountryCode', 'newBuyerCountryCode'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001299')} [ ${obj.oldBuyerCountryCode} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerCountryCode} ]`
      },
      {
        paramsKey: ['oldBuyerMobile', 'newBuyerMobile'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001300')} [ ${obj.oldBuyerMobile} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerMobile} ]`
      },
      {
        paramsKey: ['oldBuyerAddress1', 'newBuyerAddress1'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001301')} [ ${obj.oldBuyerAddress1} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerAddress1} ]`
      },
      {
        paramsKey: ['oldBuyerAddress2', 'newBuyerAddress2'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001302')} [ ${obj.oldBuyerAddress2} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerAddress2} ]`
      },
      {
        paramsKey: ['oldBuyerCity', 'newBuyerCity'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001303')}  [ ${obj.oldBuyerCity} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerCity} ]`
      },
      {
        paramsKey: ['oldBuyerState', 'newBuyerState'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001304')} [ ${obj.oldBuyerState} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerState} ]`
      },
      {
        paramsKey: ['oldBuyerPostalCode', 'newBuyerPostalCode'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001305')} [ ${obj.oldBuyerPostalCode} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerPostalCode} ]`
      },
      {
        paramsKey: ['oldBuyerPassportCode', 'newBuyerPassportCode'],
        value: `${alias2f085dd487c1465383484787b29fef2c.t('key1001306')} [ ${obj.oldBuyerPassportCode} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1000434')} [ ${obj.newBuyerPassportCode} ]`
      },
    ];
    if (Object.keys(obj).length > 0) {
      for (let key in obj) {
        addressLoglist.map((item) => {
          if (item.paramsKey.includes(key)) {
            newList.push(item.value)
          }
        })
      }
    }
    newList.unshift(`${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001307')} ${obj.packageCode} ${alias2f085dd487c1465383484787b29fef2c.t('key1001308')}`);
    let text = [...new Set(newList)].join(';');
    return text;
  }

  // 处理有些动态日志不需要展示在分销商但是需要展示运营
  function handleDynamicLogs(obj, system) {
    let text = '';
    if (contentType === 40) {
      if (system === 'distributor') {
        text = `${alias2f085dd487c1465383484787b29fef2c.t('key1001309')}${obj.currency} ${obj.amount}`;
      } else if (system === 'yms') {
        text = `${alias2f085dd487c1465383484787b29fef2c.t('key1001310')}${obj.currency} ${obj.amount}`;
      }
    }
    if (contentType === 48) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${alias2f085dd487c1465383484787b29fef2c.t('key1001311')}${obj.totalPriceCurrency}  ${obj.totalPrice}
         ${alias2f085dd487c1465383484787b29fef2c.t('key1001312')}${obj.totalPriceCurrency}  ${obj.distributorStockCost}
         ${alias2f085dd487c1465383484787b29fef2c.t('key1001313')}${obj.supplierTotalPriceCurrency}  ${obj.supplierTotalPrice}`
      }
    }
    if (contentType === 52) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${obj.subPackageCode} ${alias2f085dd487c1465383484787b29fef2c.t('key1001314')}`;
      }
    }
    return text;
  }

  /*;是用于页面显示的时候换行展示*/
  let logsObj = {
    1: `${alias2f085dd487c1465383484787b29fef2c.t('key1001315')}${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001316')}`,
    2: `${alias2f085dd487c1465383484787b29fef2c.t('key1001317')}`,
    3: `${alias2f085dd487c1465383484787b29fef2c.t('key1001318')}`,
    4: `${alias2f085dd487c1465383484787b29fef2c.t('key1001319')}`,
    5: `${alias2f085dd487c1465383484787b29fef2c.t('key1001320')}`,
    6: `${alias2f085dd487c1465383484787b29fef2c.t('key1001321')}`,
    7: `${alias2f085dd487c1465383484787b29fef2c.t('key1001322')}${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001323')} [ ${obj.packageCode} ]`,
    8: `${alias2f085dd487c1465383484787b29fef2c.t('key1001324')} ${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001323')} [ ${obj.packageCode} ${alias2f085dd487c1465383484787b29fef2c.t('key1001325')} ${obj.ruleName} ${alias2f085dd487c1465383484787b29fef2c.t('key1001326')} ${obj.ymsLogisName} ${alias2f085dd487c1465383484787b29fef2c.t('key1001327')} ${typeObj[obj.dynamicMessage]}`,
    9: `${alias2f085dd487c1465383484787b29fef2c.t('key1001328')}${obj.platformItemSku}${alias2f085dd487c1465383484787b29fef2c.t('key1001329')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    10: `${alias2f085dd487c1465383484787b29fef2c.t('key1001330')}${obj.platformItemSku}${alias2f085dd487c1465383484787b29fef2c.t('key1001331')}${setConfigCharacters}SKU`,
    11: `${alias2f085dd487c1465383484787b29fef2c.t('key1001332')}${obj.platformItemSku}${alias2f085dd487c1465383484787b29fef2c.t('key1001329')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    12: `${alias2f085dd487c1465383484787b29fef2c.t('key1001333')}${obj.platformItemSku}${alias2f085dd487c1465383484787b29fef2c.t('key1001329')}${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001334')}`,
    13: `${alias2f085dd487c1465383484787b29fef2c.t('key1001333')}${obj.platformItemSku}${alias2f085dd487c1465383484787b29fef2c.t('key1001331')}${setConfigCharacters}SKU`,
    14: `${alias2f085dd487c1465383484787b29fef2c.t('key1001324')} ${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001323')} [ ${obj.packageCode} ]`,
    15: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001323')} [ ${obj.packageCode} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1001335')}`,
    16: `${alias2f085dd487c1465383484787b29fef2c.t('key1001336')}${obj.carrierShippingMethodName} ${alias2f085dd487c1465383484787b29fef2c.t('key1001337')}${obj.trackingNumber} ${alias2f085dd487c1465383484787b29fef2c.t('key1001338')}${obj.thirdPartyNo}`,
    17: `${alias2f085dd487c1465383484787b29fef2c.t('key1001339')}${obj.message}`,
    18: `${alias2f085dd487c1465383484787b29fef2c.t('key1001340')}${obj.trackingNumber}`,
    19: `${alias2f085dd487c1465383484787b29fef2c.t('key1001341')}${obj.message}`,
    20: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001323')} [ ${obj.packageCode} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1001342')}`,
    21: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001323')} [ ${obj.packageCode} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1001343')}`,
    22: `${alias2f085dd487c1465383484787b29fef2c.t('key1000881')} ${obj.packageCode} ${alias2f085dd487c1465383484787b29fef2c.t('key1001344')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001345')})`,
    23: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001323')} [ ${obj.packageCode} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1001342')} ${obj.message}`,
    24: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001307')} ${obj.packageCode} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1001346')}`,
    25: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001307')} ${obj.packageCode} ${alias2f085dd487c1465383484787b29fef2c.t('key1001347')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001348')} [ ${obj.oldCarrierContent} ] ${alias2f085dd487c1465383484787b29fef2c.t('key1001349')} [ ${obj.newCarrierContent} ]`,
    26: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001307')} ${obj.packageCode} ${alias2f085dd487c1465383484787b29fef2c.t('key1001350')}`,
    27: handleAddress(obj),
    28: `${alias2f085dd487c1465383484787b29fef2c.t('key1001351')}`,
    29: `${alias2f085dd487c1465383484787b29fef2c.t('key1001352')}`,
    30: `${alias2f085dd487c1465383484787b29fef2c.t('key1000498')}`,
    31: `${alias2f085dd487c1465383484787b29fef2c.t('key1000497')}`,
    32: `${alias2f085dd487c1465383484787b29fef2c.t('key1001353')}`,
    33: `${alias2f085dd487c1465383484787b29fef2c.t('key1001354')}`,
    34: `${alias2f085dd487c1465383484787b29fef2c.t('key1001355')}${obj.iossName}${alias2f085dd487c1465383484787b29fef2c.t('key1001356')}${obj.iossCode}`,
    35: `${alias2f085dd487c1465383484787b29fef2c.t('key1001357')}${obj.iossName}${alias2f085dd487c1465383484787b29fef2c.t('key1001356')}${obj.iossCode}`,
    36: `${alias2f085dd487c1465383484787b29fef2c.t('key1001358')}${obj.iossName}${alias2f085dd487c1465383484787b29fef2c.t('key1001359')}${obj.iossCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001360')}${obj.platformIds}`,
    37: `${alias2f085dd487c1465383484787b29fef2c.t('key1001358')}${obj.iossName}${alias2f085dd487c1465383484787b29fef2c.t('key1001359')}${obj.iossCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001361')}${obj.shopIds}`,
    38: `${alias2f085dd487c1465383484787b29fef2c.t('key1001362')}${obj.iossName}${alias2f085dd487c1465383484787b29fef2c.t('key1001359')}${obj.iossCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001360')}${obj.platformIds}`,
    39: `${alias2f085dd487c1465383484787b29fef2c.t('key1001362')}${obj.iossName}${alias2f085dd487c1465383484787b29fef2c.t('key1001359')}${obj.iossCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001361')}${obj.shopIds}`,
    40: handleDynamicLogs(obj, system),
    41: `${alias2f085dd487c1465383484787b29fef2c.t('key1001363')}${obj.currency} ${obj.amount}`,
    42: `${alias2f085dd487c1465383484787b29fef2c.t('key1001364')}${obj.currency} ${obj.amount}`,
    43: `${alias2f085dd487c1465383484787b29fef2c.t('key1001365')}`,
    44: `${alias2f085dd487c1465383484787b29fef2c.t('key1001366')}`,
    45: `${alias2f085dd487c1465383484787b29fef2c.t('key1001367')}`,
    46: `${alias2f085dd487c1465383484787b29fef2c.t('key1001368')} ${obj.amount}${alias2f085dd487c1465383484787b29fef2c.t('key1001369')}${obj.currency}`,
    47: `${alias2f085dd487c1465383484787b29fef2c.t('key1001370')}`,
    48: handleDynamicLogs(obj, system),
    49: `${alias2f085dd487c1465383484787b29fef2c.t('key1001371')}`,
    50: `${alias2f085dd487c1465383484787b29fef2c.t('key1001372')}${obj.estimateArrivalOfGoodsTime}${alias2f085dd487c1465383484787b29fef2c.t('key1001373')}${obj.ymsSkuContent}`,
    51: `${alias2f085dd487c1465383484787b29fef2c.t('key1001374')}${obj.ymsSkuContent}`,
    52: handleDynamicLogs(obj, system),
    53: `${alias2f085dd487c1465383484787b29fef2c.t('key1001375')}`,
    54: `${alias2f085dd487c1465383484787b29fef2c.t('key1001376')}`,
    55: `${alias2f085dd487c1465383484787b29fef2c.t('key1000497')}${obj.cnValue}${alias2f085dd487c1465383484787b29fef2c.t('key1000217')}`,
    56: `${alias2f085dd487c1465383484787b29fef2c.t('key1000498')}${obj.cnValue}${alias2f085dd487c1465383484787b29fef2c.t('key1000217')}`,
    57: `${alias2f085dd487c1465383484787b29fef2c.t('key1000978')}${obj.cnValue}${alias2f085dd487c1465383484787b29fef2c.t('key1000217')}`,
    58: `${alias2f085dd487c1465383484787b29fef2c.t('key1000226')}${obj.content}`,
    59: `${alias2f085dd487c1465383484787b29fef2c.t('key1001377')}`,
    60: `${alias2f085dd487c1465383484787b29fef2c.t('key1001378')}`,
    61: `${obj.subPackageCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001314')}`,
    62: `${alias2f085dd487c1465383484787b29fef2c.t('key1001379')}`,
    63: `${alias2f085dd487c1465383484787b29fef2c.t('key1001380')}`,
    64: `${alias2f085dd487c1465383484787b29fef2c.t('key1001381')}${obj.carrierShippingMethodName}${alias2f085dd487c1465383484787b29fef2c.t('key1001382')}${obj.trackingNumber}${alias2f085dd487c1465383484787b29fef2c.t('key1001383')}${obj.thirdPartyNo}`,
    65: `${alias2f085dd487c1465383484787b29fef2c.t('key1001384')}`,
    66: `${alias2f085dd487c1465383484787b29fef2c.t('key1001385')}`,
    67: `${alias2f085dd487c1465383484787b29fef2c.t('key1001386')}`,
    68: `${alias2f085dd487c1465383484787b29fef2c.t('key1001387')}`,
    69: `${alias2f085dd487c1465383484787b29fef2c.t('key1001388')}`,
    70: `${alias2f085dd487c1465383484787b29fef2c.t('key1001389')}`,
    71: `${alias2f085dd487c1465383484787b29fef2c.t('key1001390')}${obj.rate}`,
    72: `${alias2f085dd487c1465383484787b29fef2c.t('key1001391')}${obj.rate}`,
    73: `${alias2f085dd487c1465383484787b29fef2c.t('key1001392')}`,
    74: `${alias2f085dd487c1465383484787b29fef2c.t('key1001393')}`,
    75: `${alias2f085dd487c1465383484787b29fef2c.t('key1001394')}`,
    76: `${alias2f085dd487c1465383484787b29fef2c.t('key1001395')}${obj.ymsPickingOrderCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001396')}`,
    77: `${alias2f085dd487c1465383484787b29fef2c.t('key1001164')}`,
    78: `${alias2f085dd487c1465383484787b29fef2c.t('key1001397')}`,
    79: `${alias2f085dd487c1465383484787b29fef2c.t('key1001398')}`,
    80: `${alias2f085dd487c1465383484787b29fef2c.t('key1001399')}${obj.code}】`,
    81: `${alias2f085dd487c1465383484787b29fef2c.t('key1001400')}`,
    82: `${alias2f085dd487c1465383484787b29fef2c.t('key1001401')}`,
    83: `${alias2f085dd487c1465383484787b29fef2c.t('key1001402')}`,
    84: `${alias2f085dd487c1465383484787b29fef2c.t('key1001403')}`,
    85: `${alias2f085dd487c1465383484787b29fef2c.t('key1001404')}`,
    86: `${alias2f085dd487c1465383484787b29fef2c.t('key1001405')}`,
    87: `${alias2f085dd487c1465383484787b29fef2c.t('key1001406')}`,
    88: `${alias2f085dd487c1465383484787b29fef2c.t('key1000652')}`,
    89: `${alias2f085dd487c1465383484787b29fef2c.t('key1001407')}${obj.describe}`,
    90: `${alias2f085dd487c1465383484787b29fef2c.t('key1001408')}  ${alias2f085dd487c1465383484787b29fef2c.t('key1001409')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001410')}${obj.describe}`,
    91: `${alias2f085dd487c1465383484787b29fef2c.t('key1001411')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001409')} ${alias2f085dd487c1465383484787b29fef2c.t('key1000652')}`,
    92: `${alias2f085dd487c1465383484787b29fef2c.t('key1001408')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001409')} ${alias2f085dd487c1465383484787b29fef2c.t('key1000652')}`,
    93: `${alias2f085dd487c1465383484787b29fef2c.t('key1001411')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001409')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001412')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001407')}${obj.describe}`,
    94: `${alias2f085dd487c1465383484787b29fef2c.t('key1001413')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001414')}${obj.subsidyAmount}${obj.subsidyAmountCurrency}${alias2f085dd487c1465383484787b29fef2c.t('key1001415')}${obj.modifySubsidyMoney}${obj.subsidyAmountCurrency}${alias2f085dd487c1465383484787b29fef2c.t('key1001416')}${obj.describe}`,
    95: `${alias2f085dd487c1465383484787b29fef2c.t('key1001406')}`,
    96: `${alias2f085dd487c1465383484787b29fef2c.t('key1001417')}`,
    97: `${alias2f085dd487c1465383484787b29fef2c.t('key1001418')}`,
    98: `${alias2f085dd487c1465383484787b29fef2c.t('key1001419')}`,
    99: `${alias2f085dd487c1465383484787b29fef2c.t('key1001420')}`,
    100: `${alias2f085dd487c1465383484787b29fef2c.t('key1001421')}`,
    101: `${alias2f085dd487c1465383484787b29fef2c.t('key1001422')}`,
    102: `${alias2f085dd487c1465383484787b29fef2c.t('key1001423')}${obj.auditRemarks}`,
    103: `${alias2f085dd487c1465383484787b29fef2c.t('key1001424')}`,
    104: `${alias2f085dd487c1465383484787b29fef2c.t('key1001425')}`,
    105: `${alias2f085dd487c1465383484787b29fef2c.t('key1001426')}`,
    106: `${alias2f085dd487c1465383484787b29fef2c.t('key1001427')}${obj.carrierName}：${obj.carrierShippingMethodName}`,
    107: `${alias2f085dd487c1465383484787b29fef2c.t('key1001428')}`,
    108: `${alias2f085dd487c1465383484787b29fef2c.t('key1001162')}`,
    109: `${alias2f085dd487c1465383484787b29fef2c.t('key1001429')}${obj.packageCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001430')}${obj.warehouseName}`,
    110: `${alias2f085dd487c1465383484787b29fef2c.t('key1001432')}${obj.invalidReason || alias2f085dd487c1465383484787b29fef2c.t('key1001431')}`,
    111: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001433')}${obj.oldName}${alias2f085dd487c1465383484787b29fef2c.t('key1001434')}${obj.newName}]`,
    112: `${alias2f085dd487c1465383484787b29fef2c.t('key1000881')}${obj.packageCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001324')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias2f085dd487c1465383484787b29fef2c.t('key1001435')}(${obj.ruleName})`,
    113: `${alias2f085dd487c1465383484787b29fef2c.t('key1000881')}${obj.packageCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001324')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias2f085dd487c1465383484787b29fef2c.t('key1001436')}`,
    114: `${alias2f085dd487c1465383484787b29fef2c.t('key1000881')}${obj.packageCode}${alias2f085dd487c1465383484787b29fef2c.t('key1001324')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias2f085dd487c1465383484787b29fef2c.t('key1001437')}`,
    115: `${alias2f085dd487c1465383484787b29fef2c.t('key1001438')}`,
    116: `${alias2f085dd487c1465383484787b29fef2c.t('key1001439')}`,
    117: `${alias2f085dd487c1465383484787b29fef2c.t('key1001440')}`,
    118: `${alias2f085dd487c1465383484787b29fef2c.t('key1001441')}`,
    119: `${alias2f085dd487c1465383484787b29fef2c.t('key1001442')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001443')}${obj.trackingNumber})`,
    120: `${alias2f085dd487c1465383484787b29fef2c.t('key1001439')} ${alias2f085dd487c1465383484787b29fef2c.t('key1001444')}${obj.carrierErrorMsg})`,
    121: `${alias2f085dd487c1465383484787b29fef2c.t('key1001445')}`,
    122: `${alias2f085dd487c1465383484787b29fef2c.t('key1001446')}`,
    123: `${alias2f085dd487c1465383484787b29fef2c.t('key1001447')}`,
    124: `${alias2f085dd487c1465383484787b29fef2c.t('key1001448')}${obj.businessId}】`,
    125: `${alias2f085dd487c1465383484787b29fef2c.t('key1001449')}`,
    126: `${alias2f085dd487c1465383484787b29fef2c.t('key1001450')}`,
    127: `${alias2f085dd487c1465383484787b29fef2c.t('key1001451')}`,
    128: `${alias2f085dd487c1465383484787b29fef2c.t('key1001452')}`,
    129: `${alias2f085dd487c1465383484787b29fef2c.t('key1001453')}`,
    130: `${alias2f085dd487c1465383484787b29fef2c.t('key1001454')}`,
    131: `${alias2f085dd487c1465383484787b29fef2c.t('key1001455')}`,
    132: `${alias2f085dd487c1465383484787b29fef2c.t('key1001456')}`,
    133: `${alias2f085dd487c1465383484787b29fef2c.t('key1001457')}`,
    134: `${alias2f085dd487c1465383484787b29fef2c.t('key1001458')}${obj.invalidReason}`,
    135: `${alias2f085dd487c1465383484787b29fef2c.t('key1001459')}`,
    136: `${alias2f085dd487c1465383484787b29fef2c.t('key1001460')}`,
    137: `${alias2f085dd487c1465383484787b29fef2c.t('key1001461')}`,
    138: `${alias2f085dd487c1465383484787b29fef2c.t('key1001462')}${obj.renewalPrice}${alias2f085dd487c1465383484787b29fef2c.t('key1001463')}${obj.renewalMonth} ${alias2f085dd487c1465383484787b29fef2c.t('key1001464')}`,
    139: `${alias2f085dd487c1465383484787b29fef2c.t('key1001465')}`,
    140: `${alias2f085dd487c1465383484787b29fef2c.t('key1001466')}${obj.auditRemarks}`,
    141: `${alias2f085dd487c1465383484787b29fef2c.t('key1001467')}${obj.content}`,
    142: `${alias2f085dd487c1465383484787b29fef2c.t('key1001468')}`
  };
  return logsObj[contentType];

}
