
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';
// noinspection CssUnknownTarget

import api from '@/api/';
import {getToken} from '@/utils/cookie';
import proxyFile from '../../../proxy';
import {handleSystemLanguage} from "@/utils/user";
import {dynamicLog} from "@/utils/dynamicLog";
import {MULTILINGUALNAMESLIST} from "@/utils/enum";
import {
  handlerImgSize, handerDataSorting, downloadControl, isJSON, qs,
  amountHandel, cainiaoPrint, initCainiao, handleImagesData, commonSessionStorage, isAlibabaDistributor
} from "@/utils/common";

export default {
  data() {
    return {
      sixIemCol: {  // 头部筛选区域划分为六等分
        span: 4
      },
      fourItemCol: {  // 头部筛选区域划分为四等分
        span: 6
      },
      threeItemCol: { // 头部筛选区域划分为三等分
        span: 8
      },
      twoItemCol: { // 头部筛选区域划分为二等分
        span: 12
      },
      oneItemCol: { // 头部筛选区域划分为一等分
        span: 24
      },
      headObj: {
        Authorization: getToken()
      },
      imageFormatList: ['image/png', 'image/webp', 'image/bmp', 'image/jpeg', 'image/jpg'],
      domWidth: document.documentElement.clientWidth || document.body.clientWidth || 0,
      domHeight: document.documentElement.clientHeight || document.body.clientHeight || 0,
      offsetCol: 1,
      gutterItem: 16, // 栅格间距，单位 px，左右平分
      moreFilter: false, // 高级筛选
      moreFilterIcon: 'md-arrow-dropdown',
      storeFilterMes: '',
      moreFilterMes: alias2f085dd487c1465383484787b29fef2c.t('key1000103'),
      tableLoading: false, // 列表的loading变量
      pageArray: [10, 15, 20, 50, 100], // 每页条数切换的配置
      filenodeViewTargetUrl: this.$store.state.erpConfig ? this.$store.state.erpConfig.filenodeViewTargetUrl : '',
      userInfoList: {},  // 所有用户的信息
      countrysData: [], // 国家地域的数据
      LogisticsProviderData: [], // 物流商数据
      LogisticsProviderModeData: [], // 物流商的邮寄方式数据
      system: localStorage.getItem('system'), // 展示当前系统的标识  yms系统：yms   供应商系统：supplier
      isAdmin: sessionStorage.getItem('isAdmin') ? JSON.parse(sessionStorage.getItem('isAdmin')) : this.$store.state.isAdmin, // 管理员
      placeholderSrc: './static/images/placeholder.jpg',
      failPlaceholder: require('@/assets/images/imageFail.jpg'),
      dateOptions: {
        shortcuts: [
          {
            text: alias2f085dd487c1465383484787b29fef2c.t('key1000104'), value: function () {
              let end = new Date();
              let start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: alias2f085dd487c1465383484787b29fef2c.t('key1000105'), value: function () {
              let end = new Date();
              let start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: alias2f085dd487c1465383484787b29fef2c.t('key1000106'), value: function () {
              let end = new Date();
              let start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      },
      uploadFilesMaxSize: 10240, // 上传文件大小上线（kb）
      shippingMethodModel: [],
      shippingMethodData: [],
      platformGroupList: [],
      normalizer(node) {
        return {
          id: node.ymsProductCategoryId, label: node.title, children: node.children
        };
      }, // 云卖分类自定义字段名
      ymsClassifyList: [], // 云卖分类原始数据
      currencyList: [], // 币种下拉的数据
      productServiceList: [{value: 1, name: alias2f085dd487c1465383484787b29fef2c.t('key1000107')}], // 商品服务
      labelTypeList: [
        {selected: true, value: 'null', title: alias2f085dd487c1465383484787b29fef2c.t('key1000095')},
        {selected: false, value: 'LOGIS', title: alias2f085dd487c1465383484787b29fef2c.t('key1000108')},
        {selected: false, value: 'YMS', title: alias2f085dd487c1465383484787b29fef2c.t('key1000109')},
        {selected: false, value: 'NONE', title: alias2f085dd487c1465383484787b29fef2c.t('key1000110')},
        {selected: false, value: 'MIX', title: alias2f085dd487c1465383484787b29fef2c.t('key1000111')}
      ], // 面单类型
      ymsWarehouseList: [], // 云卖仓库的数据
      shippingAccountModel: [],
      shippingMethodsList: [],
      carrierList: [],
      yunShip: [],
      optionBtnWidth: [],
      languageList: [],
      multilingualNamesList: [],
      tileBasisAddressData: [],
      addressTemplateData: [],
      configCharacters: window.businessCode || sessionStorage.getItem('businessCode') || 'YMS',
      viewerOptions: {
        navbar: false, // 是否显示缩略图底部导航栏
        title: false, // 是否显示缩略图标题栏
        zIndex: 9999,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: false, // 隐藏重置按钮
          prev: false, // 隐藏左翻页按钮
          play: false, // 隐藏播放按钮
          next: false, // 隐藏右翻页按钮
          rotateLeft: true,
          rotateRight: true,
          flipHorizontal: true,
          flipVertical: true,
        },
      }
    };
  },
  computed: {
    // 判断当前在那个系统 (true: 为供应商系统， false：为运营系统)
    systemTalg() {
      let system = localStorage.getItem('system') || this.$store.state.system;
      if (system.includes('supplier')) {
        return true;
      } else if (system.includes('yms')) {
        return false;
      }
    },
    // 币种
    currencyState() {
      return this.$store.state.currency;
    },
    // 系统设置的语种
    languagesVal() {
      let v = this;
      return localStorage.getItem('setLanguages') || v.$store.state.setLanguages || 'CN'
    },
    // 设置多语种版本的labelWidth
    labelWidth() {
      return this.languagesVal === 'CN' ? 120 : 160;
    },
  },
  methods: {
    // 控制上传图片大小
    uploadExceedSize() {
      this.$Message.error(alias2f085dd487c1465383484787b29fef2c.t('key1000112'));
    },
    // 上传文件异常
    uploadFilesError() {
      this.$Message.error(alias2f085dd487c1465383484787b29fef2c.t('key1000113'));
    },
    // 上传文件格式异常
    handleFormatError() {
      this.$Message.error(alias2f085dd487c1465383484787b29fef2c.t('key1000114'));
    },
    // 商城获取平台数据
    getShoppingPlatformData(platformIds = []) {
      let v = this;
      return new Promise(resolve => {
        let query = {
          platformIds: platformIds, platformType: 'sales'
        }
        v.axios.post(api.post_ymsPlatform_shopping_query, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              let newData = handleImagesData(data);
              resolve(newData);
            }
          }
        });
      });
    },
    // 获取已开启平台
    getEnablePlatform(platformIds = [], ymsProductCategoryId) {
      let v = this;
      let apiUrl = v.system === 'yms' ? api.post_ymsPlatform_query : api.post_ymsPlatform_api_query;
      return new Promise(resolve => {
        let query = {
          platformIds: platformIds, platformType: 'sales', ymsProductCategoryId: ymsProductCategoryId
        }
        v.axios.post(apiUrl, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              v.platformGroupList = data;
              v.$store.commit('platformGroup', v.platformGroupList);
              localStorage.setItem('platformGroup', JSON.stringify(v.platformGroupList));
              let newData = handleImagesData(data);
              resolve(newData);
            }
          }
        });
      });
    },
    /**
     *
     * @date: 2021/8/17 18:57
     * @param ymsProductGoodsIds Array
     * @param originalData 原始数据 Array | Object  对象为多维数组情况
     * @param isGetSpu 是否获取spu Boolean
     * @param isReturnResponse
     * @return copyData 增加了商品信息的原始数据 Array  增加了_productInfo 属性
     * @return isReturnResponse 是否直接返回结果
     * @param isApi 是否引用其他接口
     * @param useApiUrl 接口
     * @param system 是哪个系统使用的 默认是 yms、distributor
     */
    getProductInfoByIds(ymsProductGoodsIds, originalData, isGetSpu = false, isReturnResponse = false, isApi = false, useApiUrl, system) {
      let v = this;
      let copyData = JSON.parse(JSON.stringify(originalData));
      return new Promise(resolve => {
        let apiUrl = api.post_ymsProductInfo_queryByYmsProductGoodsIds;
        if (isApi) {
          apiUrl = api.post_ymsProductInfo_api_queryByYmsProductGoodsIds;
        }
        apiUrl = useApiUrl || apiUrl;
        let config = {
          headers: {
            'Language': v.languagesVal
          }
        }
        v.axios.post(apiUrl, ymsProductGoodsIds, config).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              data = v.handleMultilinguaTableFields(data, ['name']);
              if (isReturnResponse) {
                resolve(data);
              } else {
                if (v.typeOf(copyData) === 'object') {
                  copyData.data.forEach(ch => {
                    let child = ch;
                    copyData.childKey.forEach(param => {
                      child = child[param];
                    });
                    child.forEach((i) => {
                      data.forEach(j => {
                        j.name = v.handleMultilingualFields(j.name);
                        if (system === 'supplier') {
                          j.productGoodsList.forEach(k => {
                            if (i.productGoodsId === k.productGoodsId) {
                              if (isGetSpu) {
                                i._productInfo = j;
                              } else {
                                let obj = JSON.parse(JSON.stringify(j));
                                i._productInfo = {
                                  ...k, _spuInfo: obj
                                };
                              }
                            }
                          });
                        } else {
                          j.ymsProductGoodsList.forEach(k => {
                            if (i.ymsProductGoodsId === k.ymsProductGoodsId) {
                              if (isGetSpu) {
                                i._productInfo = j;
                              } else {
                                let obj = JSON.parse(JSON.stringify(j));
                                i._productInfo = {
                                  ...k, _spuInfo: obj
                                };
                              }
                            }
                          });
                        }
                      });
                    });
                  });
                  copyData = copyData.data;
                } else {
                  if (copyData) {
                    if (data) {
                      copyData.forEach((i, index) => {
                        data.forEach(j => {
                          j.name = v.handleMultilingualFields(j.name);
                          if (system === 'supplier') {
                            j.productGoodsList.forEach(k => {
                              if (i.productGoodsId === k.productGoodsId) {
                                if (isGetSpu) {
                                  i._productInfo = j;
                                } else {
                                  let obj = JSON.parse(JSON.stringify(j));
                                  i._productInfo = {
                                    ...k, _spuInfo: obj
                                  };
                                }
                              }
                            });
                          } else {
                            j.ymsProductGoodsList.forEach(k => {
                              if (i.ymsProductGoodsId === k.ymsProductGoodsId) {
                                if (isGetSpu) {
                                  copyData[index]._productInfo = j;
                                } else {
                                  let obj = JSON.parse(JSON.stringify(j));
                                  copyData[index]._productInfo = {
                                    ...k, _spuInfo: obj
                                  };
                                }
                              }
                            });
                          }
                        });
                      });
                      resolve(copyData);
                    } else {
                      resolve(copyData);
                    }
                  }
                }
                resolve(copyData);
              }
            } else {
              resolve(copyData);
            }
          }
        });
      });
    },
    // 获取所有仓库的数据
    getYmsWarehouse(apiUrl) {
      let v = this;
      return new Promise(resolve => {
        let useApiUrl = apiUrl || api.get_ymsWarehouse_query;
        v.axios.get(useApiUrl).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            v.ymsWarehouseList = data || [];
            resolve(data);
          }
        });
      });
    },
    /**
     * @Description: 获取所有已启用的仓库数据
     * @author jing
     * @date 2022/10/20 14:15
     * @params: status 1
     */
    getEnableWarehouseData(status) {
      let v = this;
      return new Promise(resolve => {
        v.axios.get(api.get_ymsWarehouse_queryWarehouse + status).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            resolve(data);
          }
        });
      });
    },
    /**
     * @Description: 根据仓库id获取仓库名称
     * @author jing
     * @date 2022/10/9 11:41
     * @params: ymsWarehouseId 仓库id
     */
    getWarehouseName(ymsWarehouseId) {
      let warehouseName = '-';
      let data = this.warehouseList || this.ymsWarehouseList || this.belongingWarehouseList;
      if (data.length > 0) {
        let arr = data.filter(item => item.ymsWarehouseId === ymsWarehouseId);
        if (arr && arr.length) {
          warehouseName = arr[0].name;
        }
      }
      return warehouseName;
    },

    // 页码改变的回调，返回改变后的页码
    changePage(page) {
      let v = this;
      v.curPage = page;
      v.pageParams.pageNum = page;
      v.$nextTick(() => {
        v.tableLoading = true;
        v.pageParamsStatus = true;
      })
      //v.scrollToTableTop();
    },
    // 切换每页条数时的回调，返回切换后的每页条数
    changePageSize(pageSize) {
      let v = this;
      v.pageParams.pageSize = pageSize;
      v.$nextTick(() => {
        v.tableLoading = true;
        v.pageParamsStatus = true;
        v.setPageSizeStorage(pageSize);
      })
      //v.scrollToTableTop();
    },
    setPageSizeStorage(pageSize) {
      let v = this;
      let htmlName = window.location.href.replace(/.*\/(.*?)\.html.*/, '$1');
      let path = v.$route.path;
      let _pageSizeJson = localStorage.getItem('ymsPageSize');
      let obj = {};
      if (_pageSizeJson) {
        try {
          obj = JSON.parse(_pageSizeJson);
        } catch (e) {
          console.log(e);
          obj = {};
        }
      }
      obj[htmlName + path] = pageSize;
      localStorage.setItem('ymsPageSize', JSON.stringify(obj));
    },
    getPageSizeStorage() {
      let v = this;
      let htmlName = window.location.href.replace(/.*\/(.*?)\.html.*/, '$1');
      let path = v.$route ? v.$route.path : '';
      let _pageSizeJson = localStorage.getItem('ymsPageSize');
      let pageSize = 20;
      if (_pageSizeJson) {
        try {
          let page = JSON.parse(_pageSizeJson)[htmlName + path]
          if (page) {
            pageSize = page;
          }
        } catch (e) {
          console.log(e);
          pageSize = 20;
        }
      }
      if (v.pageParams) {
        v.pageParams.pageSize = pageSize;
      }
    },
    // 中英文逗号分隔, 默认的分隔符：[',', '，', '\n', '；', ';', '.', '、']
    sepCommasFn(params, separators = [',', '，', '\n', '；', ';', '.', '、']) {
      let arr = [];
      if (params) {
        // 定义分隔符
        let currentCell = '';
        for (let i = 0; i < params.length; i++) {
          const char = params[i];
          if (separators.includes(char)) {
            // 如果是分隔符，则将当前单元格添加到结果数组中，并重置当前单元格
            if (currentCell.trim() !== '') {
              arr.push(currentCell.trim());
              currentCell = '';
            }
          }
          // 如果不是分隔符，则将字符添加到当前单元格
          else {
            currentCell += char;
          }
        }
        if (currentCell.trim() !== '') {
          arr.push(currentCell.trim());
        }
      }
      return arr;
    },
    // 设置列表展示图片的链接地址
    setImgPath(path, serviceType, imgSize, isTableImg, thumbnail = true) {
      if (path) {
        if (path.includes('http://') || path.includes('https://')) {
          return this.getThumbnailImage(path, '/thumb/', imgSize, isTableImg);
        } else {
          let talg = window.location.href.split('//');
          let link = '';
          let target = proxyFile['/'].target;
          let system = serviceType ? serviceType : localStorage.getItem('system');
          let service = '';
          let origin = window.location.origin;
          let service_arr = [
            {type: 'yms', service: '/yms-core-service'},
            {type: 'shopping', service: '/yms-core-service'},
            {type: 'supplier', service: '/yms-supplier-service'},
            {type: 'distributor', service: '/yms-distributor-service'}
          ];
          service_arr.map((item) => {
            if (item.type === system) {
              service = item.service;
            }
          });
          let filenodeViewTargetUrl = '';
          let erpConfig = this.$store.state.erpConfig;
          if (serviceType) {
            filenodeViewTargetUrl = '/filenode/s';
          }
          if (erpConfig) {
            filenodeViewTargetUrl = erpConfig.filenodeViewTargetUrl.split('.')[1];
          }
          if (filenodeViewTargetUrl) {
            if (talg[1].includes('localhost')) {
              link = target + service + filenodeViewTargetUrl + path;
              return thumbnail ? this.getThumbnailImage(link, '/thumb/', imgSize, isTableImg) : link;
            } else {
              if (serviceType) {
                link = origin + service + filenodeViewTargetUrl + path;
                return thumbnail ? this.getThumbnailImage(link, '/thumb/', imgSize, isTableImg) : link;
              } else {
                link = this.$store.state.erpConfig.filenodeViewTargetUrl + path;
                return thumbnail ? this.getThumbnailImage(link, '/thumb/', imgSize, isTableImg) : link;
              }
            }
          } else {
            return this.getThumbnailImage(path, '/thumb/', imgSize, isTableImg);
          }
        }
      } else {
        return './static/images/placeholder.jpg';
      }
    },
    tableProductInformation(h, params) {
      let v = this;
      let imgPath = '';
      let _productInfo = params.row._productInfo;
      if (_productInfo) {
        let spec = '';
        let skuImgPath = _productInfo.primaryImage;
        let spuImgPath = _productInfo._spuInfo.primaryImage;
        imgPath = skuImgPath ? skuImgPath : spuImgPath;
        let name = _productInfo._spuInfo.name;
        if (_productInfo.productGoodsSpecificationList) {
          spec = _productInfo.productGoodsSpecificationList.map(i => i.name + '：' + i.value).join('、');
        }
        return h('div', {
          class: 'flex ptb10'
        }, [v.tableImg(h, params, null, imgPath), h('div', {
          class: 'text-align-left pl10'
        }, [h('p', {class: 'mb5 color-333'}, `${v.configCharacters}SKU：` + _productInfo.ymsSku), (_productInfo._spuInfo && name) || h('p', {
          attrs: {
            title: name
          }, class: 'text-over-line3 mb5 color-666'
        }, name), h('p', {
          class: 'color-green'
        }, spec)])]);
      }
    },
    // 列表展示图片的公共处理方法
    tableImg(h, params, imgKey, imgSrc, clickHandel, placement = 'left', disabled = false, isTableImg = true) {
      let v = this;
      let imgUrl = imgSrc || params.row[imgKey] || ''
      return h('tooltip', {
        props: {
          placement: placement, theme: 'light', maxWidth: 350, transfer: true, disabled: disabled || (!imgUrl)
        }, style: {
          width: '64px', height: '64px', objectFit: 'cover', verticalAlign: 'middle'
        }
      }, [h('div', {}, [h('img', {
        attrs: {
          src: v.setImgPath(imgUrl, null, '70x70', isTableImg),
          onerror: 'javascript:this.src=\'./static/images/placeholder.jpg\''
        }, style: {
          width: '64px', height: '64px', objectFit: 'cover', verticalAlign: 'middle', cursor: 'pointer', padding: '3px'
        }, on: {
          'click'() {
            clickHandel && clickHandel();
          }
        }
      })]), h('img', {
        slot: 'content', props: {}, attrs: {
          src: v.setImgPath(imgUrl)
        }, on: {
          load(event) {
            if (event.path) {
              let dom = event.path[0];
              let imgWdith = 0;
              let imgHeight = 0;
              if (dom) {
                imgWdith = dom.naturalWidth;
                imgHeight = dom.naturalHeight;
                let obj = handlerImgSize(imgWdith, imgHeight);
                dom.style.width = obj.width;
                dom.style.height = obj.height;
                dom.style.verticalAlign = obj.verticalAlign;
              }
            }
            // 兼容火狐浏览器
            else {
              let dom = event.target;
              let imgWdith = dom.naturalWidth || 0;
              let imgHeight = dom.naturalHeight || 0;
              let obj = handlerImgSize(imgWdith, imgHeight);
              dom.style.width = obj.width;
              dom.style.height = obj.height;
              dom.style.verticalAlign = obj.verticalAlign;
            }
          }
        }
      })]);
    },

    handlerOnloadImg(dom) {
      let v = this;
      let talg = v.$refs[dom];
      if (talg) {
        let talgList = null;
        if (Array.isArray(talg)) {
          talgList = [...talg]
        } else {
          talgList = [talg]
        }
        if (talgList.length > 0) {
          talgList.map((item) => {
            let imgWdith = item.naturalWidth;
            let imgHeight = item.naturalHeight;
            let obj = handlerImgSize(imgWdith, imgHeight);
            item.style.width = obj.width;
            item.style.height = obj.height;
            item.style.verticalAlign = obj.verticalAlign;
            v.$forceUpdate();
          });
        }
      }
    },
    /**
     * @date: 2021/8/24 16:53
     * @param params carrierIds Array
     * 批量获取物流商名称
     */
    getCarrierNames(params, value) {
      let v = this;
      let carrierIds = [...new Set(params)];
      return new Promise(resolve => {
        v.axios.get(api.get_carrier_queryByIds + `?carrierIds=${carrierIds}`, {loading: value}).then(response => {
          if (response.data.code === 0) {
            resolve(response.data.datas);
          }
        });
      });
    },
    // 获取批量查询邮寄方式的数据
    GetQueryCarrierShippingName(list) {
      let v = this;
      let url = '';
      if (v.system === 'distributor') {
        url = api.post_carrierShippingMethod_queryCarrierShippingName_api;
      } else {
        url = api.post_carrierShippingMethod_queryCarrierShippingName;
      }
      return new Promise(resolve => {
        v.axios.post(url, list).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              resolve(data);
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        });
      });
    },
    /**
     * @Description: 获取所有用户信息
     * @author jing
     * @date 2021/7/20 13:46
     * @params:
     */
    getUserName() {
      let v = this;
      switch (v.system) {
        case 'yms':
          url = api.get_ymsCoreUserInfo_getAllUser;
          break;
        case 'supplier':
          url = api.get_ymsSupplierUserInfo_getAllUser;
          break;
        case 'distributor':
          url = api.get_ymsDistributorUserInfo_getAllUser;
          break;
      }
      let url = url + '?time=' + new Date().getTime();
      v.userInfoList = {};
      return new Promise(resolve => {
        v.axios.get(url).then(response => {
          if (response.data.code === 0) {
            if (response.data.datas !== null) {
              v.userInfoList = response.data.datas;
              resolve(v.userInfoList);
            }
          } else {
            resolve(true);
          }
        }).catch((err) => {
          resolve(true);
          console.log('--获取用户名数据失败---', err);
        });
      });
    },
    // 回到顶部
    scrollTopFun() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    /**
     * @Description: 根据数组中的某个属性去重
     * @author jing
     * @date 2021/3/11 18:02
     * @params:
     */
    uniqueFunc(...args) {
      const paramsLength = arguments.length;
      if (paramsLength === 1) {
        let arr = arguments[0];
        return [...new Set(arr)];
      } else {
        const res = new Map();
        const flag = arguments[1];
        return arguments[0].filter(item => !res.has(item[flag]) && res.set(item[flag], item));
      }
    },
    // 获取input的焦点
    getFocus(id) {
      document.getElementById(id).focus();
    },
    /**
     * @Description: 根据数组的属性值进行分组的方法
     * @author jing
     * @date: 2021/3/19 17:08
     * @params: array 数组, fun 遍历的属性值函数, type 根据的属性值
     */
    handerGrouping(array, fun, type, list = 'data') {
      let groups = {};
      array.forEach(function (item) {
        let group = JSON.stringify(fun(item));
        groups[group] = groups[group] || [];
        groups[group].push(item);
      });
      let new_arr = [];
      Object.keys(groups).map(function (group) {
        new_arr.push({
          [type]: groups[group][0][type], [list]: groups[group]
        });
      });
      return new_arr;
    },
    // 深拷贝
    deepCopy(data) {
      let v = this;
      let t = v.typeOf(data);
      let o;
      if (t === 'array') {
        o = [];
      } else if (t === 'object') {
        o = {};
      } else {
        return data;
      }
      if (t === 'array') {
        for (let i = 0; i < data.length; i++) {
          o.push(v.deepCopy(data[i]));
        }
      } else if (t === 'object') {
        for (let i in data) {
          o[i] = v.deepCopy(data[i]);
        }
      }
      return o;
    },
    typeOf(obj) {
      let map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
      };
      return map[Reflect.toString.call(obj)];
    },
    /**
     * 转html实体
     * */
    entityToString(entity) {
      let div = document.createElement('div');
      div.innerHTML = entity;
      return div.innerText || div.textContent;
    },
    // 判断页面操作按钮权限
    getPermission(name) {
      let v = this;
      let talg = false;
      let roleList = commonSessionStorage.getItem('roleList') || v.$store.state.roleList;
      if (name) {
        talg = roleList[name];
      }
      return !!(v.isAdmin || talg);
    },
    // 判断页面的权限
    getPermissionCallback(name) {
      if (name === '') return;
      return new Promise(resolve => {
        let v = this;
        let data = v.$store.state.roleData || commonSessionStorage.getItem('roleData');
        if (data) {
          if (v.isAdmin || data.includes(name)) {
            resolve(true);
          } else {
            v.gotoError();
          }
        }
      });
    },
    // 排序的操作按钮
    modifyTheSort(index, status) {
      let v = this;
      v.pageParams.orderBy = v.buttonGroupModel[index].type;
      v.buttonGroupModel.forEach((n, i) => {
        if (i === index && n.selected) {
          v.buttonGroupModel[index].status = !v.buttonGroupModel[index].status;
        } else n.selected = i === index && !n.selected;
      });
      v.pageParams.upDown = v.buttonGroupModel[index].status ? 'up' : 'down';
      v.pageParamsStatus = true;
    },
    // 展开与收起检索条件
    toggleMoreFilter() {
      let v = this;
      if (v.moreFilter) {
        v.moreFilter = false;
        v.moreFilterMes = v.storeFilterMes;
        v.moreFilterIcon = 'md-arrow-dropdown';
      } else {
        v.moreFilter = true;
        v.storeFilterMes === '' ? v.storeFilterMes = v.moreFilterMes : '';
        if (v.pageParams && v.pageParams.searchValue && v.pageParams.searchValue !== '') {
          v.pageParams.searchValue = '';
        }
        v.moreFilterMes = alias2f085dd487c1465383484787b29fef2c.t('key1000115');
        v.moreFilterIcon = 'md-arrow-dropup';
      }
    },
    /**
     * @Description: 获取仓库id
     * @author gan
     * @date: 2020/10/19 14:31
     * @params:
     */
    getWarehouseId() {
      return (sessionStorage.getItem('warehouseId') || this.$store.state.warehouseId || localStorage.getItem('warehouseId'));
    },
    // 获取币种下拉
    getCurrencyInfo() {
      let v = this;
      let pos = [];
      return new Promise(resolve => {
        let newData = [];
        if (localStorage.getItem('currencyData')) {
          newData = JSON.parse(localStorage.getItem('currencyData'));
        }
        if (newData.length > 0) {
          handleData(newData);
        } else {
          v.axios.get(api.get_currencyInfo + '?time=' + new Date().getTime()).then(response => {
            let data = response.data.datas || [];
            if (data.length > 0) {
              localStorage.setItem('currencyData', JSON.stringify(data));
              handleData(data);
            }
          });
        }

        function handleData(data) {
          data.forEach((n) => {
            let label = n.currencyCode;
            if (n.currencyCnName) {
              label = label + '-' + n.currencyCnName;
            }
            pos.push({
              label: label,
              value: n.currencyCode
            });
          });
          v.currencyList = pos;
          resolve(data);
        }
      });
    },
    // 高级筛选国家列表获取
    countryChange(value) {
      let v = this;
      if (value.indexOf('null') >= 0) {
        v.pageParams.buyerCountryCodeList = null;
      } else {
        v.pageParams.buyerCountryCodeList = value;
      }
    },
    // 处理areaCode数据
    handleAreaCode(twoCode) {
      let areaCode = '';
      if (this.countrysData.length > 0) {
        this.countrysData.map((item) => {
          if (item.twoCode === twoCode) {
            areaCode = item.areaCode;
          }
        });
        return areaCode;
      }
    },
    // 获取国家列表（下拉框默认展示中文名字）
    getCountrysData(type = 'cn') {
      let v = this;
      return new Promise(resolve => {
        let config = {
          headers: {
            'Language': v.languagesVal
          }
        }
        v.axios.get(api.get_common_countrys_query, config).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            let areaCodeList = ['86', '852', '886'];
            if (data) {
              data.map((item) => {
                if (item.areaCode) {
                  if (type === 'en') {
                    if (areaCodeList.includes(item.areaCode)) {
                      item['twoCodeName'] = item.cnName;
                      item['areaCodeName'] = item.cnName + ' +' + item.areaCode;
                    } else {
                      item['twoCodeName'] = item.enName;
                      item['areaCodeName'] = item.enName + ' +' + item.areaCode;
                    }
                  }
                }
              })
            }
            v.countrysData = data.filter((item) => {
              return item.areaCode !== null
            });
            resolve(v.countrysData)
          }
        });
      });
    },
    // 跳转到无权限页面
    gotoError() {
      this.$router.push('/noPersermission');
    },
    // 获取物流方式
    loadData(item, callback) {
      item.loading = true;
      let v = this;
      let url = '';
      // 不关联仓库查询
      if (item.notAssociated) {
        url = api.get_enableShippingMethods + '?carrierId=' + item.value;
      }
      // 关联仓库查询
      else {
        url = api.get_enableShippingMethods + '?carrierId=' + item.value + '&warehouseId=' + v.getWarehouseId();
      }
      v.axios.get(url).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            v.shippingMethodsList = data;
            let pos = [];
            for (let i = 0; i < data.length; i++) {
              pos.push({
                value: [data[i].shippingMethodId, item.carrierAccounts],
                label: data[i].carrierShippingMethodName
              });
              if (i === data.length - 1) {
                item.children = pos;
                item.loading = false;
                callback();
              }
            }
            if (data.length === 0) {
              item.children = pos;
              item.loading = false;
              v.$Message.warning(alias2f085dd487c1465383484787b29fef2c.t('key1000116'));
              callback();
            }
          }
        } else {
          item.loading = false;
          item.children = [];
        }
      }).catch(() => {
        item.loading = false;
        item.children = [];
      });
    },
    showShippingData(value, notAssociated) {
      if (value) {
        this.getShippingList(notAssociated);
      }
    },
    getShippingList(notAssociated) {
      let v = this;
      let url = '';
      // 不关联仓库查询
      if (notAssociated) {
        url = api.get_enableCarriers;
      }
      // 关联仓库查询邮寄方式
      else {
        url = api.get_enableCarriers + '?warehouseId=' + v.getWarehouseId() + '&isFilter=true' + '&time=' + new Date().getTime();
      }
      v.axios.get(url).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            v.carrierList = data;
            let pos = [];
            for (let i = 0; i < data.length; i++) {
              pos.push({
                value: data[i].carrierId,
                label: data[i].carrierName,
                children: [],
                loading: false,
                carrierAccounts: data[i].carrierAccounts,
                notAssociated: notAssociated
              });
              if (i === data.length - 1) {
                v.shippingMethodData = pos;
              }
            }
          }
        }
      });
    },
    // 获取物流方式的动态参数
    getCarrierBaseSettingApiMatch(shippingMethodId, model) {
      // 提取公共方法  此方法用于 /matchTheLogistics 物流方式 所有平台
      let v = this;
      v.carrierBaseSetting = [];
      v.shippingMethodModel = [];
      v.axios.get(api.get_carrierParam_queryForShippingMethodParams + '?shippingMethodId=' + shippingMethodId + '&time=' + new Date().getTime() + Math.random() * 100).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          data.forEach((n) => {
            if (model) {
              v[model].push({
                paramKey: n.paramKey,
                paramValue: n.paramValue,
                title: n.paramName
              });
            } else {
              v.shippingMethodModel.push({
                paramKey: n.paramKey,
                paramValue: n.paramValue,
                title: n.paramName
              });
            }
          });
          v.carrierBaseSetting = data;
        } else {
          v.$Message.error(alias2f085dd487c1465383484787b29fef2c.t('key1000117'));
        }
      });
    },
    // 打印
    printHtmlUrl(path, id) {
      let v = this;
      // 如果打印地址为html结尾 需要调用接口
      if (!path) return;

      if (path && path.indexOf('http') === -1) {
        let erpCommon = v.$store.state.erpConfig;
        if (erpCommon.filenodeViewTargetUrl === './filenode/s') {
          erpCommon.filenodeViewTargetUrl = window.location.href.split(/[^/]+(?!.*html)/)[0] + 'filenode/s';
        }
        path = erpCommon.filenodeViewTargetUrl + path;
      }
      return new Promise(resolve => {
        let str;
        let last;
        str = path.split('.');
        last = str[str.length - 1];
        if (last === 'html') {
          v.axios.get(api.get_loadUrlTxt + '?url=' + path).then(res => {
            if (res.data.code === 0) {
              let data = res.data.datas;
              resolve({
                content: data,
                postId: id
              });
            }
          });
        } else if (last === 'cainiao') {
          // 获取文本内容
          initCainiao().then((data) => {
            let websocket = data;
            v.axios.get(api.get_loadUrlTxt + '?url=' + path).then(response => {
              if (response.data.code === 0) {
                // 调用菜鸟打印
                let data = response.data.datas;
                cainiaoPrint(websocket, id, response.data.datas).then(() => {
                  resolve({
                    content: data,
                    postId: id,
                    printType: 'cainiao'
                  });
                });
              }
            });
          });
        } else {
          if (path.includes('.png')) {
            // 70*20 mm x pt  系数 0.3527
            resolve({
              content: `<div style="width: 198pt">
                            <img width="100%" src="${path}"  alt=""/>
                        </div>`,
              postId: id
            });
          } else {
            resolve({
              pdf: path,
              postId: id
            });
          }
        }
      });
    },
    // 校验密码不能输入中文 (默认校验密码必填，required 为false时，不校验密码必填) 可共用
    validatePasswordnoMatchChinese(required = true) {
      let v = this;
      let isnoMatchChinese = v.$regular.noMatchChinese;
      return function (rule, value, callback) {
        if (required) {
          if (value === '') {
            callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000118')));
          } else if (isnoMatchChinese.test(value)) {
            callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000119')));
          }
        } else {
          if (value && isnoMatchChinese.test(value)) {
            callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000119')));
          }
        }
        callback();
      };
    },
    // 校验邮箱格式 (默认校验邮箱必填，required 为false时，不校验邮箱必填) 可共用
    validateMailboxFormat(required = true) {
      let v = this;
      let isMailbox = v.$regular.isMailbox;
      return function (rule, value, callback) {
        if (required) {
          if (value === '') {
            callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000120')));
          } else if (!isMailbox.test(value)) {
            callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000121')));
          }
        } else {
          if (value && !isMailbox.test(value)) {
            callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000121')));
          }
        }
        callback();
      };
    },
    // 校验手机号 可共用
    validateMobile(required = true) {
      let v = this;
      let isPhone = v.$regular.isPhone;
      return function (rule, value, callback) {
        if (required) {
          if (!value) {
            return callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000122')));
          } else if (!isPhone.test(value)) {
            return callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000123')));
          }
        } else {
          if (value && !isPhone.test(value)) {
            return callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000123')));
          }
        }
        callback();
      };
    },

    // 校验区号+手机号 可共用
    validateOverseasMobile(required = true) {
      let v = this;
      let AllNumber = v.$regular.AllNumber;
      return function (rule, value, callback) {
        if (required) {
          if (!value) {
            return callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000122')));
          } else if (!AllNumber.test(value)) {
            return callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000124')));
          }
        } else {
          if (value && !AllNumber.test(value)) {
            return callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000124')));
          }
        }
        callback();
      };
    },

    // 校验固定电话 可共用
    validateFixedTelephone() {
      let v = this;
      let fixedTelephone = v.$regular.fixedTelephone;
      return function (rule, value, callback) {
        if (value && !fixedTelephone.test(value)) {
          return callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000125')));
        } else {
          callback();
        }
      };
    },

    // 校验省市区地址
    validateCascaderAddressValue() {
      return function (rule, value, callback) {
        if (!value || value.length <= 0) {
          callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000126')));
        } else {
          if (value.length < 3) {
            callback(new Error(alias2f085dd487c1465383484787b29fef2c.t('key1000127')));
          } else {
            callback();
          }
        }
      }
    },

    // 删除的模态框模板
    isDelModal(okCallback, cancelCallback, text) {
      let v = this;
      let content = text || alias2f085dd487c1465383484787b29fef2c.t('key1000128');
      v.$Modal.confirm({
        title: alias2f085dd487c1465383484787b29fef2c.t('key1000129'), content: '<p>' + content + '</p>', onOk: () => {
          okCallback && okCallback();
        }, onCancel: () => {
          cancelCallback && cancelCallback();
        }
      });
    },
    processObjTime(obj, paramsList) {
      paramsList.forEach(val => {
        if (obj[val]) obj[val] = this.$uDate.dealTime(obj[val], 'fulltime');
      });
      return obj;
    },
    processTimeData(list, key) {
      if (typeof key === 'string') {
        list.forEach(val => {
          if (val[key]) val[key] = this.$uDate.dealTime(val[key], 'fulltime');
        });
      } else if (Array.isArray(key)) {
        list.forEach(val => {
          key.forEach(item => {
            val[item] = val[item] ? this.$uDate.dealTime(val[item], 'fulltime') : '';
          });
        });
      }
      return this.handleMultilinguaTableFields(list, ['goodsCnDesc']);
    },
    // 给分类数据做个排序
    sortCategory(a, b) {
      const sortStr = ['productCategoryNavigation', 'sortNo'];
      let aArrLength = a[sortStr[0]].split('->').length;
      let bArrLength = b[sortStr[0]].split('->').length;
      if (aArrLength !== bArrLength) return aArrLength < bArrLength ? -1 : 1;
      else if (a[sortStr[1]] !== b[sortStr[1]]) return a[sortStr[1]] < b[sortStr[1]] ? -1 : 1;
    },
    /**
     * @Description: 获取云卖分类的数据
     * @author jing
     * @date 2021/7/9 10:38
     * @params: 类型type 默认获取有层级关系的树状数据，type值为 tile时，获取平铺的数据, original 返回原始数据
     */

    // 获取所有分类的数据
    getCloudTreeData(url, type, expand, ymsSiteId = null, params) {
      let v = this;
      let config = {
        headers: {
          'Language': v.languagesVal
        }
      }
      let obj = commonSessionStorage.getItem('erpConfig');
      let commonYmsSiteId = obj ? obj.ymsSite ? obj.ymsSite.ymsSiteId : null : null;
      let query = {
        ymsSiteId: ymsSiteId || commonYmsSiteId,
        ...params
      }
      return new Promise(resolve => {
        v.axios.post(url, query, config).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              let classifyList = data.sort(v.sortCategory);
              v.ymsClassifyList = classifyList;
              if (type === 'original') {
                resolve(data);
              } else if (type === 'tile') {
                let arr = v.handlerClassifySort(classifyList);
                arr.map((item) => {
                  item.title = item.productCategoryNavigation;
                });
                resolve(arr);
              } else {
                let new_arr = v.handlerClassifyData(classifyList, 'productCategoryNavigation', '->', expand);
                let newData = [];
                if (new_arr) {
                  newData = v.IterationDelateMenuChildren(new_arr);
                }
                resolve(newData);
              }
            } else {
              resolve([]);
            }
          }
        });
      });
    },
    // 处理分类的数据
    handlerClassifyData(data, key, sign, expand = true) {
      let obj = {};
      if (data.length > 0) {
        let newArr = [];
        data.forEach(i => {
          i.expand = false;
          let matchName;
          i.switchTalg = false;
          if (expand) {
            i.expand = true;
          }
          let name = i[key];
          if (name.lastIndexOf(sign) > -1) {
            matchName = name.slice(0, name.lastIndexOf(sign));
            i.title = name.slice(name.lastIndexOf(sign) + 2, name.length);
          } else {
            matchName = name;
            i.title = matchName;
            newArr.push(i);
          }
          if (obj[matchName]) {
            obj[matchName].children.push(i);
          }
          obj[name] = i;
          obj[name].children = [];
        });
        this.$store.commit('classificationData', newArr);
        if (!isAlibabaDistributor()) {
          localStorage.setItem('classificationData', JSON.stringify(newArr));
        }
        return newArr;
      }
    },

    // 处理商品分类数据平铺后，排序的问题
    handlerClassifySort(data) {
      let v = this;
      data.map((item) => {
        item.levelName = item.productCategoryNavigation.split('->')[0];
      });
      let new_arr = v.handerGrouping(data, function (item) {
        return [item.levelName];
      }, 'levelName');
      let classifyData = [];
      let noSortNoList = [];
      if (new_arr.length > 0) {
        new_arr.map((item) => {
          item.classify_1 = [];
          item.classify_2 = [];
          item.classify_3 = [];
          item.levelList = [];
          item.sortNo = null;
          let level_1 = [];
          let level_2 = [];
          item.data.map((ele) => {
            let level = ele.productCategoryNavigation.split('->').length;
            if (level === 1) {
              level_1.push(ele.productCategoryNavigation.split('->')[0]);
              item.sortNo = ele.sortNo;
              item.classify_1.push(ele);
            }
            if (level === 2) {
              level_2.push(ele.productCategoryNavigation.split('->')[1]);
              item.classify_2.push(ele);
            }
            if (level === 3) {
              item.classify_3.push(ele);
            }
          });
          if (item.classify_1.length > 0) {
            item.classify_1.map((talg_1) => {
              talg_1.sortNo = talg_1.sortNo * 10;
            });
            if (item.classify_2.length > 0) {
              item.classify_2.map((talg_2) => {
                let levelName_2 = talg_2.productCategoryNavigation.split('->')[0];
                item.classify_1.map((talg_1) => {
                  let levelName_1 = talg_1.productCategoryNavigation.split('->')[0];
                  if (levelName_2 === levelName_1) {
                    talg_2.sortNo = (talg_2.sortNo + talg_1.sortNo) * 100;
                  }
                });
              });
              for (let i = item.classify_2.length - 1; i >= 0; i--) {
                if (level_1.indexOf(item.classify_2[i].productCategoryNavigation.split('->')[0]) < 0) {
                  item.classify_2.splice(i, 1);
                }
              }
            }
            if (item.classify_3.length > 0) {
              item.classify_3.map((talg_3) => {
                let levelName_3 = talg_3.productCategoryNavigation.split('->')[1];
                item.classify_2.map((talg_2) => {
                  let levelName_2 = talg_2.productCategoryNavigation.split('->')[1];
                  if (levelName_3 === levelName_2) {
                    talg_3.sortNo = (talg_3.sortNo + talg_2.sortNo) * 1000;
                  }
                });
              });
              for (let i = item.classify_3.length - 1; i >= 0; i--) {
                if (level_2.indexOf(item.classify_3[i].productCategoryNavigation.split('->')[1]) < 0) {
                  item.classify_3.splice(i, 1);
                }
              }
            }
            item.classify_1.sort(handerDataSorting('sortNo', 'asc'));
            item.classify_2.sort(handerDataSorting('sortNo', 'asc'));
            item.classify_3.sort(handerDataSorting('sortNo', 'asc'));
            item.levelList = [...item.classify_1, ...item.classify_2, ...item.classify_3];
          }
        });
        new_arr.map((talg) => {
          if (talg.sortNo) {
            classifyData.push(...talg.levelList);
          } else {
            noSortNoList.push(...talg.levelList);
          }
        });
      }
      return [...classifyData, ...noSortNoList];
    },

    // 递归删除children中长度为空的数组
    IterationDelateMenuChildren(arr, value) {
      let v = this;
      if (arr.length) {
        for (let i in arr) {
          if (arr[i].children && arr[i].children.length) {
            v.IterationDelateMenuChildren(arr[i].children);
          } else {
            if (value) {
              arr[i].children = null;
            } else {
              delete arr[i].children;
            }
          }
        }
      }
      return arr;
    },
    /**
     * @Description: 获取运营系统所有属性设置的数据
     * @author jing
     * @date 2021/8/17 14:55
     * @params: types
     */
    getCommonSetting(query) {
      let v = this;
      return new Promise(resolve => {
        v.axios.post(api.post_erpCommonSettingParam_queryByParams, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            resolve(data);
          }
        });
      });
    },
    /**
     * @Description: (运营系统)根据库位id获取仓库的库区、仓库的相关详情数据
     * @author jing
     * @date 2021/8/17 17:13
     * @params: ymsWarehouseLocationIds data
     * @return isReturnResponse 是否返回结果
     */
    getWarehouseManageInfo(ymsWarehouseLocationIds, tableData, isReturnResponse = false, apiUrl) {
      let v = this;
      return new Promise(resolve => {
        let useApiUrl = apiUrl || api.post_ymsWarehouse_ymsProductInfo_ymsWarehouseLocationIds;
        v.axios.post(useApiUrl, ymsWarehouseLocationIds).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (isReturnResponse) {
              resolve(data);
            } else {
              if (data) {
                if (v.typeOf(tableData) === 'object') {
                  tableData.data.forEach(ch => {
                    let child = ch;
                    tableData.childKey.forEach(param => {
                      child = child[param];
                    });
                    child.forEach(item => {
                      data.forEach(j => {
                        j.ymsWarehouseBlocks.forEach(k => {
                          k.ymsWarehouseLocations.forEach(l => {
                            if (item.ymsWarehouseLocationId === l.ymsWarehouseLocationId) {
                              item.warehouseName = j.name;
                              item.warehouseBlockName = k.name;
                              item.warehouseLocalName = l.code;
                            }
                          });
                        });
                      });
                    });
                  });
                  tableData = tableData.data;
                } else {
                  tableData.forEach((item) => {
                    data.forEach(j => {
                      j.ymsWarehouseBlocks.forEach(k => {
                        k.ymsWarehouseLocations.forEach(l => {
                          if (item.ymsWarehouseLocationId === l.ymsWarehouseLocationId) {
                            item.warehouseName = j.name;
                            item.warehouseBlockName = k.name;
                            item.warehouseBlockCode = k.code;
                            item.warehouseLocalName = l.code;
                          }
                        });
                      });
                    });
                  });
                }
              }
              resolve(tableData);
            }
          }
        });
      });
    },
    priceTransform(price) {
      if (price) {
        let num = price + '';
        if (num.includes('.')) {
          let arr = num.split('.');
          return arr[0].replace(/\B(?=(\d{3})+$)/g, ",") + '.' + arr[1]
        } else {
          return num.replace(/\B(?=(\d{3})+$)/g, ",")
        }
      } else {
        return 0;
      }
    },

    /**
     * @Description: 当前系统的图片，有时候加载失败，对加载失败的图片进行优化处理，加载失败时，默认展示加载失败的默认图
     * @author jing
     * @date 2021/9/15 17:09
     * @params:
     */
    setErrorImg(event) {
      let img = event.srcElement;
      img.src = require('@/assets/images/imageError.jpg');
      img.onerror = null;
    },
    /**
     * @Description: 弹出弹窗时，禁止底层页面滚动
     * @author jing
     * @date 2021/10/20 11:09
     * @params:
     */
    stopScroll() {
      let talg = function (e) {
        if (e.cancelable) {
          if (!e.defaultPrevented) {
            e.preventDefault();
          }
        }
      };
      setTimeout(() => {
        document.body.setAttribute('style', 'overflow-y:hidden !important');
        document.addEventListener('touchmove', talg, false);
      }, 500);
    },
    removeStopScroll() {
      let talg = function (e) {
        if (e.cancelable) {
          if (!e.defaultPrevented) {
            e.preventDefault();
          }
        }
      };
      document.body.removeAttribute('style');
      setTimeout(() => {
        document.body.setAttribute('style', 'overflow-y:auto !important');
      }, 400);
      document.removeEventListener('touchmove', talg, false);
    },
    // 云卖物流查询已启用的数据
    getYunLogisticsAll() {
      let v = this;
      let config = {
        headers: {
          'Language': v.languagesVal
        }
      }
      v.yunShip = [];
      let urlObj = {
        yms: api.post_ymsLogisService_queryAll, distributor: api.post_ymsLogisService_api_queryAll + '?merchantType=2'
      }
      let url = urlObj[v.system] || '';
      return new Promise((resolve) => {
        if (url) {
          v.axios.post(url, null, config).then(response => {
            if (response.data.code === 0) {
              v.yunShip = response.data.datas || [];
              resolve(v.yunShip);
            }
          });
        }
      });
    },
    // 云卖物流查询所有数据
    getYunLogisticsAllData() {
      let v = this;
      return new Promise((resolve) => {
        let query = {
          name: null, carrierId: null, shippingMethodId: null, status: null, merchantType: null, language: null,
        }
        v.axios.post(api.post_ymsLogisService_api_queryList, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            resolve(data);
          }
        });
      });
    },

    /**
     * @Description:  websocket连接后台服务实时更新消息通知的数据
     * @author jing
     * @date 2021/12/10 17:22
     * @params:
     */
    getMessageNotificationData(url) {
      let v = this;
      v.axios.get(url).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          let messageReminderList = [];
          if (data.length > 0) {
            data.map((item) => {
              item.timer = v.$uDate.dealTime(item.updatedTime, 'fulltime');
            });
            if (data.length >= 5) {
              messageReminderList = data.slice(0, 5);
            } else {
              messageReminderList = data;
            }
          }
          v.$store.commit('unreadTotal', data.length);
          v.$store.commit('unreadData', messageReminderList);
        }
      });
    },
    /**
     * @Description: 打印的公共方法
     * @author jing
     * @date 2022/1/11 14:11
     * @params: val 链接地址  postId  打印的包裹号  text  打印成功之后的提示文本
     */
    commonPrint(val, postId, text = alias2f085dd487c1465383484787b29fef2c.t('key1000130')) {
      let v = this;
      return new Promise((resolve) => {
        v.printHtmlUrl(val, postId).then(res => {
          if (res.printType === 'cainiao') {
            v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000131') + '：' + postId + alias2f085dd487c1465383484787b29fef2c.t('key1000130'));
            return;
          }
          val = res;
          if (val.content) {
            val.content = encodeURIComponent(val.content);
          } else {
            val.pdf = encodeURIComponent(val.pdf);
          }
          let instance = v.axios.create({
            timeout: 3000, headers: {
              'Content-Type': 'multipart/form-data; charset=UTF-8'
            }
          });
          delete v.axios.defaults.headers.post['X-Requested-With'];
          instance.post('http://localhost:10099/print', qs(val)).then(response => {
            if (response.status === 200) {
              v.$Message.success(text);
              resolve(true);
            }
          }).catch(() => {
            let fileName = '/tool/TongtoolPrinter.exe';
            downloadControl(fileName).then(() => {
              v.tableLoading = false;
            });
          });
        });
      });
    },
    /**
     * @Description: 打印文档内容htmlText的公共方法
     * @author jing
     * @date 2022/2/9 10:57
     * @params: service：对应的系统服务,  htmlText：打印的文本内容
     */

    publicPrintBtn(service, htmlText) {
      let v = this;
      return new Promise((resolve) => {
        let path = `${window.location.origin}/${service}/static/printFont/`;
        let htm5Doc = `<!DOCTYPE html>
                  <html lang="en">
                    <head>
                    <meta charset="UTF-8">
                    <title>Title</title>
                    <style type="text/css">
                    *{margin: 0;padding: 0;color: #000000;outline:0}
                @media print {.btn{display: none;}}
                  @font-face {
                  font-family: 'IDAutomationC128S';
                  src: url('${path}IDAutomationC128S.eot'); /* IE9 Compat Modes */
                  src: url('${path}IDAutomationC128S.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
                  url('${path}IDAutomationC128S.woff') format('woff'), /* Modern Browsers */
                  url('${path}IDAutomationC128S.ttf') format('truetype'), /* Safari, Android, iOS */
                  url('${path}IDAutomationC128S.svg#IDAutomationC128S') format('svg'); /* Legacy iOS */
                }

                @font-face {
                  font-family: 'IDAutomationSC39S';
                  src: url('${path}IDAutomationSC39S.eot'); /* IE9 Compat Modes */
                  src: url('${path}IDAutomationSC39S.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
                  url('${path}IDAutomationSC39S.ttf') format('truetype');
                }
                </style>
                    </head>
                     <body class="box">` + htmlText + `
                     </body>
                  </html>`;
        let instance = v.axios.create({
          timeout: 3000, transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
              ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            return ret;
          }], headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        instance.post('http://localhost:10099/print', {
          content: htm5Doc, postId: '1'
        }).then(response => {
          if (response.status === 200) {
            v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000130'));
            resolve(true);
          }
        }).catch(() => {
          let fileName = '/tool/TongtoolPrinter.exe';
          downloadControl(fileName).then(() => {
            v.tableLoading = false;
          });
          resolve(true);
        });
      });
    },
    /**
     * @Description: 表格滚动条回到初始位置
     * @author jing
     * @date 2022/3/15 18:03
     * @params:
     */
    scrollToTableTop() {
      setTimeout(() => {
        document.getElementsByClassName('ivu-table-body')[0].scrollTo(0, 0)
      }, 400)
    },

    /**
     * @Description: 批量生成售卖资料的公共方法
     * @author jing
     * @date 2023/6/7 9:48
     * @params:
     */

    generateSalesInfoFun(type, ymsProductId, merchantId, listingStatus, currentlySelectedData) {
      let v = this;
      let query = {
        distributorMerchantId: merchantId, type: Number(listingStatus), ymsProductIds: []
      };
      // 批量
      if (type === 'all') {
        if (currentlySelectedData.length > 0) {
          query.ymsProductIds = currentlySelectedData.map((item) => {
            return item.ymsProductId
          });
          if (v.generationTalg) {
            query.isRegeneration = v.isRegeneration;
            query.isBatchType = 1; // 是否是批量
            handleGenerateSalesInforAlertsData(query, type);
          } else {
            v.$Modal.confirm({
              title: alias2f085dd487c1465383484787b29fef2c.t('key1000132'),
              content: alias2f085dd487c1465383484787b29fef2c.t('key1000133'),
              okText: alias2f085dd487c1465383484787b29fef2c.t('key1000134'),
              cancelText: alias2f085dd487c1465383484787b29fef2c.t('key1000097'),
              onOk: () => {
                query.isBatchType = 1; // 是否是批量
                handleGenerateSalesInforAlertsData(query, type);
              },
              onCancel: () => {
              }
            });
          }
        } else {
          v.$Message.warning(alias2f085dd487c1465383484787b29fef2c.t('key1000135'));
          return false;
        }
      }
      // 点击单个
      else if (type === 'single') {
        query.ymsProductIds = [ymsProductId];
        v.$Modal.confirm({
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000132'),
          content: alias2f085dd487c1465383484787b29fef2c.t('key1000133'),
          okText: alias2f085dd487c1465383484787b29fef2c.t('key1000134'),
          cancelText: alias2f085dd487c1465383484787b29fef2c.t('key1000097'),
          onOk: () => {
            handleGenerateSalesInforAlertsData(query, type);
          },
          onCancel: () => {
          }
        });
      }

      // 处理生成售卖资料
      function handleGenerateSalesInforAlertsData(query, type) {
        v.axios.post(api.post_ymsDistributorProductInfo_product, query).then(response => {
          if (response.data.code === 0) {
            if (type === 'all') {
              v.generationTalg = false;
              v.generateSalesInforAlertsModal = false;
            }
            let data = response.data.datas;
            if (data) {
              v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000136'));
            }
          }
        });
      }
    }, /**
     * @Description: 获取地区国家的基础数据
     * @author jing
     * @date 2023/8/29 10:18
     * @params:
     */
    getRegionalRcountriesData(loading) {
      let v = this;
      return new Promise((resolve => {
        let config = {
          headers: {
            'Language': v.languagesVal, loading: loading
          }
        }
        v.axios.get(api.get_common_country_queryZoneCountry, config).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            resolve(data);
          }
        });
      }));
    },

    /**
     * @Description: 根据countryId（国家id）获取对应省级区域的基础数据
     * @author jing
     * @date 2022/6/6 11:38
     * @params: countryId
     */
    getStateData(countryId) {
      let v = this;
      return new Promise((resolve => {
        let config = {
          headers: {
            'Language': v.languagesVal
          }
        }
        v.axios.get(api.get_common_state_queryByCountryId + `?countryId=${countryId}`, config).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data && data.length > 0) {
              resolve(data);
            }
          }
        });
      }));
    },

    /**
     * @Description: 根据stateCode获取对应市级区域的基础数据
     * @author jing
     * @date 2022/6/6 11:44
     * @params: stateCode
     */

    getCityData(stateCode) {
      let v = this;
      return new Promise((resolve => {
        let config = {
          headers: {
            'Language': v.languagesVal
          }
        }
        v.axios.get(api.get_common_city_queryByStateCode + `?stateCode=${stateCode}`, config).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data && data.length > 0) {
              resolve(data);
            }
          }
        });
      }));
    },

    /**
     * @Description: 根据cityCode获取对应区级区域的基础数据
     * @author jing
     * @date 2022/6/6 11:46
     * @params: cityCode
     */
    getDistrictData(cityCode) {
      let v = this;
      return new Promise((resolve => {
        let config = {
          headers: {
            'Language': v.languagesVal
          }
        }
        v.axios.get(api.get_common_district_queryByCityCode + `?cityCode=${cityCode}`, config).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data && data.length > 0) {
              resolve(data);
            }
          }
        });
      }));
    },
    /**
     * @Description: 在运营、分销商、供应商系统点击商品名称跳转到商城的商品详情页
     * @author jing
     * @date 2022/7/18 15:41
     * @params: ymsProductId
     */
    // 点击中文名称跳转到商城详情页
    toShopDetails(ymsProductId, type) {
      if (ymsProductId) {
        let systemList = ['yms', 'distributor', 'supplier'];
        if (systemList.includes(this.system)) {
          let talg = window.location.href.split('//');
          let url = `/index.html#/shopDetails/${ymsProductId}`;
          if (type) {
            url = url + type;
          }
          if (talg[1].includes('localhost')) {
            let link = `${talg[0]}//${window.location.host}${url}`;
            window.open(link, '_blank');
          } else {
            let link = `${talg[0]}//${window.location.host}/yms-shopping-service/${url}`;
            window.open(link, '_blank');
          }
        }
      }
    }, /**
     * @Description: 强密码生成
     * @author jing
     * @date 2022/8/9 16:12
     * @params:
     */
    getStrongPassword(obj, value) {
      let v = this;
      const length = 16;
      let chars;
      if (!chars) chars = 'abcdefghijkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ23456789*&%$#@!';
      let randomChars = '';
      for (let x = 0; x < length; x++) {
        let i = Math.floor(Math.random() * chars.length);
        randomChars += chars.charAt(i);
      }
      v[obj][value] = randomChars;
      v.$nextTick(() => {
        v.getFocus(obj + '_' + value);
      });
    },
    /**
     * @Description: 默认设置时间段筛选的开始、结束时间的时分秒为00:00:00  23:59:59
     * @author jing
     * @date 2022/9/21 9:52
     * @params: value 时间段  talg: 是否转换格式符号， 默认转换
     */
    defaultTimePeriod(value, talg = true) {
      if (talg) {
        let newTimer = [];
        let start = value[0].replace(/-/g, '/');
        let end = value[1].replace(/-/g, '/');
        if (start.includes('00:00:00')) {
          newTimer[0] = start.slice(0, -8) + '00:00:00';
        } else {
          newTimer[0] = start;
        }
        if (end.includes('00:00:00')) {
          newTimer[1] = end.slice(0, -8) + '23:59:59';
        } else {
          newTimer[1] = end;
        }
        return newTimer;
      } else {
        if (value[1].slice(-8) === '00:00:00') {
          value[1] = value[1].slice(0, -8) + '23:59:59';
        }
        return value;
      }
    },
    /**
     * @Description: 获取供应商系统的归属仓库的数据
     * @author jing
     * @date 2022/10/27 14:07
     * @params: supplierMerchantId 供应商商户号
     */
    getBelongingWarehouseData(supplierMerchantId) {
      let v = this;
      return new Promise(resolve => {
        v.axios.get(api.get_ymsSupplierAscriptionWarehouse_api_query + supplierMerchantId).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            v.belongingWarehouseList = data;
            resolve(data);
          }
        });
      });
    },
    /**
     * @Description: 分销商系统获取对应商户的所属仓库的基础数据
     * @author jing
     * @date 2022/10/28 16:55
     * @params: distributorMerchantId   分销商商户号
     */
    getDistributorWarehouseData(distributorMerchantId) {
      let v = this;
      return new Promise(resolve => {
        v.axios.get(api.get_ymsDistributorAscriptionWarehouse_api_query + distributorMerchantId).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            resolve(data);
          }
        });
      });
    },
    /**
     * @Description: 默认的时间段条件查询的范围
     * @author jing
     * @date 2022/11/15 11:07
     * @params:
     * days 默认查询的时间范围天数
     * value 时间选择器值变量
     * startTimeKey、endTimeKey 筛选时间段的key
     */
    defaultTimePeriodRange(days, value, startTimeKey, endTimeKey) {
      let v = this;
      let end = new Date();
      let start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * days);
      // 设置开始时间的时分秒为 00:00:00
      start.setHours(0, 0, 0, 0);
      // 设置结束时间的时分秒为 23:59:59
      end.setHours(23, 59, 59, 999);
      let startTime = v.$uDate.getDateTime(start, 'fulltime');
      let endTime = v.$uDate.getDateTime(end, 'fulltime');
      v[value] = [startTime, endTime];
      v.pageParams[startTimeKey] = v.$uDate.getUniversalTime(startTime, 'fulltime');
      v.pageParams[endTimeKey] = v.$uDate.getUniversalTime(endTime, 'fulltime');
    },
    /**
     * @Description: 处理币种符号的问题(商城)
     * @author jing
     * @date 2022/11/21 16:51
     * @params: currency 币种
     */
    currencySymbol(currency) {
      let currencyData = [];
      let currencySymbol = '';
      if (localStorage.getItem('currencyData')) {
        currencyData = JSON.parse(localStorage.getItem('currencyData'));
      }
      if (currencyData.length > 0) {
        currencyData.map((item) => {
          if (item.currencyCode === currency) {
            currencySymbol = item.currencySymbol
          }
        });
      }
      return currencySymbol ? ' ' + currencySymbol + ' ' : ' ';
    },

    /**
     * @Description: 获取指定尺寸大小的缩略图方法
     * @author jing
     * @date 2023/1/10 16:01
     * @params:
     * imagePath 当前原图的路径
     * size 尺寸大小(默认获取400*400的尺寸的缩略图)
     * thumbnailPath 指定缩略图大小的路径
     * isTableImg 是否列表图片
     */
    getThumbnailImage(imagePath, thumbnailPath = '/thumb/', imgSize, isTableImg) {
      let imgUrl = '';
      let size = imgSize ? imgSize : '400x400';
      let thumbnailImg = thumbnailPath + size;
      let pathLiast =
        ['/index', '/shopList', '/shopDetails', '/productList', '/productDetails',
          '/productCenter/productGoods', '/approvalList', '/approvalDetails', '/wms/inventoryLevels',
          '/commodityList', '/commoditySortingOperation'];
      if (imagePath) {
        // 如果是手动上传的图片还是拿原图，如果当前图片是缩略图就不处理
        let path = this.$route.path;
        let name = this.$route.name;
        if (!(imagePath.includes('http://') || imagePath.includes('https://')) || imagePath.includes(thumbnailImg)) {
          if (this.system === 'supplier') {
            if (!imagePath.includes(thumbnailImg)) {
              if (!imagePath.includes('/temp/')) {
                handleImg(imagePath, thumbnailPath, size)
              } else {
                imgUrl = imagePath;
              }
            } else {
              imgUrl = imagePath;
            }
          } else {
            imgUrl = imagePath;
          }
        }
        // 涉及到商品图片的才换成缩略图、列表涉及到图片的
        else if (pathLiast.includes(path) || pathLiast.includes(name) || isTableImg) {
          handleImg(imagePath, thumbnailPath, size)
        } else {
          imgUrl = imagePath;
        }
      }

      function handleImg(imagePath, thumbnailPath, size) {
        let talgPath = '/filenode/s';
        let list = imagePath.split('/filenode/s');
        if (list.length > 0) {
          imgUrl = `${list[0]}${talgPath}${thumbnailPath}${size}${list[1]}`
        }
      }

      return imgUrl;
    },
    /**
     * @Description: 处理下拉框批量操作按钮没有权限隐藏问题
     * @author jing
     * @date 2023/3/22 9:37
     * @params:
     */
    batchButtonPermissions(permissionKeyList) {
      let roleList = this.$store.state.roleList;
      if (this.isAdmin) {
        return true;
      } else {
        if (permissionKeyList.length > 0) {
          return permissionKeyList.some((item) => {
            return roleList[item.permissionKey]
          });
        }
      }
    },
    /**
     * @Description: 判断是否开启仓储设置
     * @author jing
     * @date 2023/3/31 13:43
     * @params:
     * verification：true 默认校验是否开启仓储设置   false 不校验
     * type： 默认是仓储管理  warehouseManage
     */
    getSupplierWarehouseSetting(verification = true, type = 'warehouseManage') {
      let v = this;
      let paramValue = null;
      if (v.system === 'supplier') {
        return new Promise(resolve => {
          v.axios.post(api.post_erpCommonSettingParam_queryMerchantByParamKeys, ['isWarehouseManagement']).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              if (data && data.length > 0) {
                data.map((item) => {
                  if (item.paramKey === 'isWarehouseManagement') {
                    paramValue = item.paramValue;
                  }
                });
                if (verification) {
                  if (type === 'warehouseManage') {
                    if (paramValue === 'N') {
                      v.$Modal.warning({
                        title: alias2f085dd487c1465383484787b29fef2c.t('key1000137'), okText: alias2f085dd487c1465383484787b29fef2c.t('key1000138'), onOk: () => {
                          window.location.reload();
                        }
                      });
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  } else if (type === 'operationManage') {
                    if (paramValue === 'Y') {
                      v.$Modal.warning({
                        title: alias2f085dd487c1465383484787b29fef2c.t('key1000139'), okText: alias2f085dd487c1465383484787b29fef2c.t('key1000138'), onOk: () => {
                          window.location.reload();
                        }
                      });
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  }
                } else {
                  v.$store.commit('isWarehouseManagement', paramValue)
                  v.topNavActiveName = localStorage.getItem('topNavActiveName') || 'operationManage';
                  // 手动更新默认选中的菜单
                  v.$nextTick(function () {
                    if (v.$refs.horizontalMenu) {
                      v.$refs.horizontalMenu.updateOpened();
                      v.$refs.horizontalMenu.updateActiveName();
                    }
                  });
                  resolve(true);
                }
              }
            }
          });
        })
      }
    },
    /**
     * @Description: 处理规格属性的标点符号
     * @author jing
     * @date 2023/4/7 16:38
     * @params:
     */
    handlePunctuationMark(str) {
      if (str) {
        return str.replace(/[:：,，]/g, match => {
          switch (match) {
            case ':':
            case '：':
              return '：';
            case ',':
            case '，':
              return '、';
            default:
              return match;
          }
        });
      }
    },
    /**
     * @Description: 处理金额格式按照千分位显示
     * @author jing
     * @date 2023/4/14 15:21
     * @params: digitNum 千分位展示时，默认保留两位小数点，不足时自动补0
     */
    formatCurrency(value, digitNum = 2) {
      let amount = 0;
      if (value) {
        let val = Number(value);
        let obj = {
          minimumFractionDigits: digitNum, // 表示数字小数部分的最小位数，不足位数时后面用 0 补齐。
          maximumFractionDigits: digitNum, // 表示数字小数部分的最大位数，超过位数时四舍五入。
        }
        const formatter = new Intl.NumberFormat('en-US', obj);
        amount = formatter.format(val);
      }
      return amount;
    },
    /**
     * @Description: 校验列表中在指定的宽度和文本限制行数下是否有文本内容溢出，如果有文本溢出则用Tooltip提示
     * @author jing
     * @date 2023/6/19 11:46
     * @params:
     * text  文本内容
     * width 列表中单元格指定的宽度
     * maxLines 文本限制的行数，默认限制两行
     * colorStyle 文本显示的颜色
     */
    checkContentOverflow(h, text, width, maxLines = 2, colorStyle) {
      let isOverflow = false;
      if (text) {
        const hiddenDiv = document.createElement('div');
        hiddenDiv.style.visibility = 'hidden';
        hiddenDiv.style.fontSize = '12px';
        hiddenDiv.style.width = `${width - 40}px`;
        hiddenDiv.innerText = text;
        hiddenDiv.style.wordWrap = 'break-word';
        hiddenDiv.style.wordBreak = 'break-all';
        document.body.appendChild(hiddenDiv);
        const lineHeight = parseInt(getComputedStyle(hiddenDiv).lineHeight)
        const maxHeight = lineHeight * maxLines;
        isOverflow = hiddenDiv.offsetHeight > maxHeight;
        document.body.removeChild(hiddenDiv);
      }
      let Tooltip = h('Tooltip', {
        props: {
          placement: 'top', maxWidth: 400, transfer: true
        }
      }, [h('div', {class: `ellipsis_${maxLines}`}, text), h('div', {
        slot: 'content', style: {
          whiteSpace: 'normal', wordBreak: 'break-all'
        }
      }, text)]);
      let ordinary = h('div', {
        style: {
          color: colorStyle
        }
      }, text);
      return isOverflow ? Tooltip : ordinary;
    },
    /**
     * @Description: 获取对应系统指引所属模块的菜单数据
     * @author jing
     * @date 2023/5/15 11:51
     * @params: menuData 菜单数据   systemType: 1分销商系统  2运营系统  3供应商系统
     */
    getSystemModuleList(menuData, systemType) {
      let newList = [];
      let newData = [];
      if (systemType === '3') {
        for (let key in menuData) {
          newList.push(...menuData[key]);
        }
      } else {
        newList = menuData;
      }

      function treeToList(tree, result = []) {
        tree.forEach(node => {
          result.push(node)
          node.children && treeToList(node.children, result)
        })
        return result
      }

      let list = treeToList(newList);
      if (list.length > 0) {
        newData = list.filter((item) => {
          return item.guidanceModule
        });
      }
      return newData;
    },
    /**
     * @Description: 查询当前商户设置的语种，没有设置就默认获取当前浏览器设置的语言语种
     * @author jing
     * @date 2023/7/13 11:13
     * @params:
     */
    getUserSettingLanguage(userInfo) {
      let v = this;
      let key = 'language';
      return new Promise((resolve) => {
        if (userInfo) {
          let query = {
            paramKeys: [key], businessId: userInfo.userId
          }
          v.axios.post(api.post_erpCommonSettingParam_queryByUK, query).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas || [];
              let val = '';
              if (data.length > 0) {
                data.map((item) => {
                  if (item.paramKey === key) {
                    val = item.paramValue;
                    v.$store.commit('setLanguages', val);
                    localStorage.setItem('setLanguages', val);
                  }
                });
                resolve(val);
              } else {
                resolve('CN');
              }
            }
          });
        } else {
          let lang = localStorage.getItem('lang') || handleSystemLanguage();
          resolve(lang);
        }
      });
    },

    /**
     * @Description: 系统默认选中的语种
     * @author jing
     * @date 2023/7/13 10:55
     * @params:
     */
    systemDefaultLanguage(code) {
      let v = this;
      return new Promise((resolve) => {
        let obj = MULTILINGUALNAMESLIST;
        let newList = [];
        let configureLanguage = localStorage.getItem('configureLanguage');
        let data = v.$store.state.configureLanguage || configureLanguage ? JSON.parse(configureLanguage) : [];
        if (data.length > 0) {
          v.languageList = data;
          data.map((item) => {
            if (item.code === code) {
              v.flagPath = item.iconPath;
              v.selectLang = item.title;
            }
            newList.push({
              code: item.code, name: item.name, title: obj[item.code] || '', required: item.code === 'CN'
            })
          });
          v.multilingualNamesList = newList;
          resolve(true)
        }
      });
    },

    /**
     * @Description: 多语种单个入参的处理
     * @author jing
     * @date 2023/7/10 14:53
     * @params:
     * 当前多语种命名的字段的格式：multilingualName_ + 多语种的类型   例：multilingualName_CN 中文名称的字段
     * 以json的形式传给后台  例：name:{'CN': '中文名称', 'EN': '英文名称'}
     */
    handleMultilingualSingleParam(obj, fields) {
      let names = {};
      if (Object.keys(obj).length > 0) {
        for (let key in obj) {
          if (key.includes(fields)) {
            let type = key.split(fields)[1];
            if (type) {
              names[type] = obj[key] || '';
            }
          }
        }
      }
      return JSON.stringify(names);
    },

    /**
     * @Description: 多语种多个入參的处理
     * @author jing
     * @date 2023/7/20 10:27
     * @params:
     */
    handleMultilingualMultipleParam(obj, keyList) {
      let v = this;
      if (Object.keys(obj).length > 0) {
        let reg = new RegExp('^(' + keyList.join('|') + ')');
        let params = Object.keys(obj).reduce((acc, key) => {
          if (!key.match(reg)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {});
        keyList.map((item) => {
          let key = item.split('_')[0];
          params[key] = v.handleMultilingualSingleParam(obj, item);
        });
        return params;
      }
    },

    /**
     * @Description:  多语种出参字段展示的统一处理
     * @author jing
     * @date 2023/7/19 9:55
     * @params:  fields  字段名
     */
    handleMultilingualFields(fields, value) {
      let v = this;
      let text = '';
      if (fields) {
        if (isJSON(fields)) {
          let obj = JSON.parse(fields);
          // 默认先按照当前配置的语种取值，取不到指定语种值就默取中文值，中文值没有就默认取原始值
          if (value) {
            text = obj[v.languagesVal] || obj['CN'] || '';
          } else {
            text = obj[v.languagesVal] || obj['CN'] || fields || '';
          }
        } else {
          text = fields;
        }
      }
      return text;
    },

    /**
     * @Description: 表单多语种出参赋值的统一处理
     * @author jing
     * @date 2023/7/20 10:46
     * @params:
     */
    handleMultilingualFormFields(data, keylist) {
      let form = {};
      keylist.map((key) => {
        if (data[key]) {
          if (isJSON(data[key])) {
            let obj = JSON.parse(data[key]);
            for (let k in obj) {
              form[key + '_' + k] = obj[k] || '';
            }
          }
          // 当没有配置多语种就默认展示中文值
          else {
            form[key + '_CN'] = data[key];
          }
        } else {
          form[key + '_CN'] = '';
        }
      });
      return form;
    },

    /**
     * @Description: 列表中多个字段多语种出参赋值的统一处理
     * @author jing
     * @date 2023/7/20 11:19
     * @params:
     */
    handleMultilinguaTableFields(data, keyList, value) {
      let v = this;
      if (data && data.length > 0) {
        data.map((item) => {
          for (let key in item) {
            if (keyList.includes(key)) {
              item[key] = v.handleMultilingualFields(item[key], value);
            }
          }
        });
        return data;
      }
    },


    /**
     * @Description: 初始化多语种名称表单校验的字段名
     * @author jing
     * @date 2023/7/10 16:55
     * @params: 默认拼接 multilingualName_  例： multilingualName_CN  中文名称字段
     */
    initMultilingualFields(fields, val, keyList) {
      let v = this;
      return new Promise((resolve) => {
        v.systemDefaultLanguage().then(() => {
          let obj = JSON.parse(JSON.stringify(val));
          if (v.multilingualNamesList.length > 0) {
            v.multilingualNamesList.map((item) => {
              if (keyList.length > 0) {
                keyList.map((key) => {
                  let type = key + item.code;
                  obj[type] = '';
                });
              } else {
                obj[item.code] = '';
              }
            })
          }
          v[fields] = obj;
          resolve(obj);
        });
      })
    }, /**
     * @Description: 处理动态多语种日志的展示问题
     * @author jing
     * @date 2023/7/25 14:05
     * @params: 多语种日志--目前只处理分销商系统涉及的日志信息
     */

    handleMultilingualLogs(tableData, system) {
      if (tableData.length > 0) {
        tableData.map((item) => {
          let content = item.content;
          if (item.contentType !== 0 && item.contentType !== null) {
            if (isJSON(content)) {
              let text = JSON.parse(content);
              let newText = dynamicLog(text, item.contentType, system);
              if (newText) {
                item.logContent = newText.split(';');
              }
            } else {
              // 在分销商系统需要屏蔽的动态日志类型
              let distributorDynamicLogsList = [45, 49];
              if (system === 'distributor') {
                if (distributorDynamicLogsList.includes(item.contentType)) {
                  item.logContent = '';
                } else {
                  if (item.contentType === 44) {
                    item.logContent = alias2f085dd487c1465383484787b29fef2c.t('key1000140');
                  } else {
                    item.logContent = item.content;
                  }
                }
              } else {
                item.logContent = dynamicLog({}, item.contentType, system);
              }
            }
          } else {
            item.logContent = item.content;
          }
        });
      }
      return tableData;
    },
    /**
     * @Description: 根据国家的twoCode(二次码)获取对应国家的省/洲市区的基础数据
     * @author jing
     * @date 2023/8/2 16:53
     * @params: twoCode
     */
    getProvinceBasicData(twoCode) {
      let v = this;
      let newData = [];
      let config = {
        headers: {
          'Language': v.languagesVal
        },
        loading: true
      }
      let url = '';
      // 供应商入驻得先暂时走供应商服务调接口
      if (v.$route.path === '/supplierSettlement') {
        url = '/yms-supplier-service/erpCommon/yms-core-service/common/country/getDetailByTwoCode/';
      } else {
        url = api.get_common_country_getDetailByTwoCode;
      }
      return new Promise(resolve => {
        v.axios.get(url + twoCode, config).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              function replaceChildren(obj) {
                for (const key in obj) {
                  if (typeof obj[key] === "object") {
                    if (Array.isArray(obj[key])) {
                      obj[key].forEach((item) => replaceChildren(item));
                      if (['states', 'cities', 'districts'].includes(key)) {
                        // 将states、cities、districts替换为children
                        obj['children'] = JSON.parse(JSON.stringify(obj[key]));
                        delete obj[key];
                      }
                    }
                  }
                }
              }

              function treeToList(tree, result = []) {
                tree.forEach(node => {
                  result.push(node)
                  node.children && treeToList(node.children, result)
                })
                return result
              }

              replaceChildren(data);
              let list = treeToList(data.children);
              if (list.length > 0) {
                list.map((item) => {
                  item.value = item.code;
                  item.label = item.cnName;
                });
                this.tileBasisAddressData = list; // 平铺之后的省市区数据
              }
              newData = data.children || [];
              resolve(newData);
            }
          }
        });
      });
    },
    /**
     * @Description: 获取省市区的名称
     * @author jing
     * @date 2023/8/3 11:20
     * @params:
     */

    handleAddressName(obj) {
      let v = this;
      let stateName = '';
      let cityName = '';
      let districtName = '';
      let addressName = '';
      if (v.tileBasisAddressData.length > 0) {
        v.tileBasisAddressData.map((item) => {
          if (item.code === obj.state) {
            stateName = item.cnName;
          }
          if (item.code === obj.city) {
            cityName = item.cnName;
          }
          if (item.code === obj.district) {
            districtName = item.cnName;
          }
        });
        addressName = stateName + ' / ' + cityName + ' / ' + districtName;
      }
      return addressName;
    },
    /**
     * @Description: 处理自定义列的排序问题
     * @author jing
     * @date 2023/8/4 15:37
     * @params:
     * sortKeyList：当前列表已经设置展示的字段值
     * hideKeyColumns：根据需求屏蔽的列表字段
     * originalColumns：列表字段的原始数据
     * defaultSelectKeyList：列表默认展示的字段，不参与自定义列排序
     */
    handleCustomColumnsSort(sortKeyList, hideKeyColumns, originalColumns, defaultSelectKeyList) {
      let v = this;
      let newColumns = [];
      if (sortKeyList.length > 0) {
        originalColumns.map((item) => {
          if (!sortKeyList.includes(item.key)) {
            hideKeyColumns.push(item.key);
          }
        });
        hideKeyColumns = v.uniqueFunc(hideKeyColumns);
        newColumns = originalColumns.filter((item) => {
          return !hideKeyColumns.includes(item.key)
        });
        newColumns.sort((a, b) => sortKeyList.indexOf(a.key) - sortKeyList.indexOf(b.key));
        defaultSelectKeyList.map((key, idx) => {
          originalColumns.map((item) => {
            if (item.key === key) {
              if (idx <= 1) {
                newColumns.unshift(item)
              } else {
                newColumns.push(item);
              }
            }
          });
        });
      }
      let columns = v.uniqueFunc(newColumns, 'key');
      return columns;
    },
    /**
     * @Description: 更新列表dom
     * @author jing
     * @date 2023/8/4 17:39
     * @params:
     */
    updateTableDom(dom, timer = 1000, scrollTalg = true, tableListKey = 'tableData') {
      let v = this;
      v.$nextTick(() => {
        v.tableLoading = false;
      });
      if (v[tableListKey] && v[tableListKey].length > 0) {
        v.$nextTick(() => {
          setTimeout(() => {
            if (v.$refs[dom] && scrollTalg) {
              v.$refs[dom].$el.querySelector('.ivu-table-body').scrollTop = 0;
            }
            v[tableListKey] = [...v[tableListKey]];
          }, timer)
        });
        v.$forceUpdate();
      }
      v.$forceUpdate();
    },
    /**
     * @Description: 默认时间查询的公共方法
     * @author jing
     * @date 2023/8/22 14:39
     * @params:
     * type: last_month 最近一个月   three_months  最近三个月
     * selectValue: 下拉时间框的选项
     * fieldsType: '',   多个时间筛选框的字段
     */
    setDefaultTimerParams(type, selectValue, fieldsType) {
      let tagTimer = '';
      switch (type) {
        case 'last_month':
          tagTimer = 3600 * 1000 * 24 * 30;
          break;
        case 'three_months':
          tagTimer = 3600 * 1000 * 24 * 90;
          break;
      }
      let end = new Date();
      let start = new Date();
      start.setTime(start.getTime() - tagTimer);
      let startTime = this.$uDate.getDateTime(start, 'fulltime');
      let endTime = this.$uDate.getDateTime(end, 'fulltime');
      let time = [startTime, endTime];
      let setTimer = handleTime(time);

      function handleTime(value) {
        let newTimer = [];
        let start = value[0].replace(/-/g, '/');
        let end = value[1].replace(/-/g, '/');
        newTimer[0] = start.slice(0, -8) + '00:00:00';
        newTimer[1] = end.slice(0, -8) + '23:59:59';
        return newTimer;
      }

      if (selectValue) {
        this.selectTime({date: setTimer, value: selectValue});
      } else {
        this.getDateValue(setTimer, fieldsType)
      }
    },

    /**
     * @Description: 处理和组装地址模板数据
     * @author jing
     * @date 2023/10/10 15:33
     * @params:
     */
    getAddressTemplateData(type = 'yms') {
      let v = this;
      if (type === 'yms') {
        let query = {
          addressName: '', orderBy: 'createdTime', status: 1, // 获取已启用的数据
          upDown: 'down'
        };
        v.addressTemplateData = [];
        return new Promise(resolve => {
          v.axios.post(api.post_ymsAddressTemplate_chooseList, query).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas || [];
              if (data.length > 0) {
                data.map((item) => {
                  let name = item.addressName + '<br/>' + item.name + ' ' + item.phone + '<br/>' +
                    item.state + '<br/>' + item.city + '<br/>' + item.district + '<br/>' + item.street;
                  v.addressTemplateData.push(Object.assign({}, item, {templateName: name}))
                });
                resolve(v.addressTemplateData)
              }
            }
          });
        });
      } else {
        return new Promise(resolve => {
          v.axios.get(api.get_ymsAddressTemplate_api_chooseList).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas || [];
              if (data.length > 0) {
                v.addressTemplateData = data;
                resolve(v.addressTemplateData)
              }
            }
          });
        });
      }
    },
    /**
     * @Description: 跳转到刊登系统的指定页面的方法
     * @author jing
     * @date 2023/11/3 14:12
     * @params: url  跳转到Listing的指定页面路径
     */
    goToListingPage(url) {
      let v = this;
      let path = '';
      let merchant = v.$store.state.erpConfig.merchant;
      if (merchant) {
        let listingDomain = merchant.listingDomain;
        let ymsListingDomain = merchant.ymsListingDomain;
        if (!(v.$store.state.openClothingCategories)) {
          path = listingDomain + url;
        } else if (merchant.listingStatus === '1') {
          path = listingDomain + url;
        } else {
          path = ymsListingDomain + url;
        }
        path = path.includes('http') ? path : 'https://' + path;
        window.open(path, '_blank');
      } else {
        v.$Message.error(alias2f085dd487c1465383484787b29fef2c.t('key1000141'));
        return false;
      }
    },
    /**
     * @Description: 获取IOSS税率数据
     * @author jing
     * @date 2023/12/12 14:32
     * @params:
     */
    getIossTaxRateData(type) {
      return new Promise(resolve => {
        let obj = {
          yms: api.get_ymsIossRateSetting_query, distributor: api.get_ymsIossRule_queryRateSetting
        }
        this.axios.get(obj[type]).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (type === 'distributor') {
              data = data.filter((item) => {
                return item.status === 1
              })
            }
            resolve(data)
          } else {
            resolve([])
          }
        });
      });
    },

  },
  watch: {
    '$route': {
      handler() {
        let v = this;
        v.getPageSizeStorage();
      }, immediate: true, deep: true
    }
  }
};
